import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useStyles2, Button, Icon } from '@grafana/ui';
import { useFFTProfilesRefs } from './useFFTProfilesRefs';
import { useAppState } from '../AppContext/AppStateContext';
import { getBackendSrv } from '@grafana/runtime';
import { sliceDate } from '../utils/helpers';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export interface FFTReferenceProps {}

export const FFTReference: React.FunctionComponent<FFTReferenceProps> = () => {
  const { state, dispatch } = useAppState();
  const { currentProfile, fftSessionRef, allProfiles, firstSessionValues, beacon, datasources } = state;
  const profiles = useFFTProfilesRefs();
  const [isRef, setIsRef] = useState(false);

  const displayCurrentProfileRef = async () => {
    let refToDisplay = [];
    if (!profiles.length) {
      return;
    }

    refToDisplay = profiles?.filter((el: { profile: string }) => el.profile === currentProfile);
    if (!refToDisplay.length) {
      setIsRef(false);
      return;
    }

    setIsRef(true);
    let fftRefValues = [] as any;
    let splitDate = refToDisplay[0].time.split('+');
    let timeOffset = new Date(refToDisplay[0].time).getTimezoneOffset() / 60;

    const querySettings = `SELECT * FROM Signature_SpectrumZoom WHERE ("device" ='${beacon}') AND time='${splitDate[0]}'${timeOffset}h`;
    const queryResult = await getBackendSrv()
      .get(
        `api/datasources/proxy/uid/${datasources?.influx?.uid}/query?db=${datasources?.influx?.name}&q=${querySettings}`
      )
      .catch(() => {
        dispatch({ type: 'LIST_IS_LOADING', payload: false });
        console.log('error while loading list');
      });

    if (queryResult?.results[0]?.series) {
      fftRefValues = queryResult.results[0]?.series[0]?.values[0]
        .filter((el: any, index: number) => index > 1)
        .filter((el: any) => el !== null)
        .filter((el: any) => typeof el === 'number');
    } else {
      return;
    }

    dispatch({
      type: 'SET_REF_PROFILE',
      payload: [
        {
          ...refToDisplay[0],
          values: fftRefValues,
        },
      ],
    });
  };

  useEffect(() => {
    if (allProfiles.length) {
      displayCurrentProfileRef();
    }
  }, [allProfiles, currentProfile, firstSessionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    fftSessionRef.length ? setIsRef(true) : setIsRef(false);
    if (fftSessionRef.length && fftSessionRef[0].profile !== currentProfile) {
      dispatch({ type: 'SET_REF_PROFILE', payload: [] });
    }
  }, [fftSessionRef]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{isRef && <DisplayRefInfos fftRef={fftSessionRef[0]} />}</>;
};

export const DisplayRefInfos = ({ fftRef }: any) => {
  const { state, dispatch } = useAppState();
  const { datasources, country, user, beacon } = state;
  const styles = useStyles2(getStyles);
  // const formatedTime = formatTimeToLocale(fftRef?.time, lang);

  const WRITE_API_URL = `api/datasources/proxy/uid/${datasources.json.uid}`;
  const FFT_REFERENCE_ENDPOINT = `${WRITE_API_URL}/fft_reference`;

  const removeReference = async () => {
    const payloadReference = {
      mac_address: beacon,
      profile_value: fftRef.profile,
    };

    await getBackendSrv()
      .delete(FFT_REFERENCE_ENDPOINT, payloadReference, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(() => {
        console.log('error while deleting fft reference');
      });

    dispatch({ type: 'SET_REF_PROFILE', payload: [] });
    // Remove all profiles to allow retrieve fresh data
    dispatch({ type: 'SET_PROFILES', payload: [] });
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.fftName}>
        REFERENCE <span className={styles.sessionText}>Session {fftRef?.session}</span>
      </div>
      <div className={styles.timeText}>{sliceDate(fftRef?.time)?.replace('T', ' ')}</div>
      {user.orgRole !== 'Viewer' && (
        <Button onClick={() => removeReference()} className={styles.removeRef} size="sm" variant="secondary">
          <Icon name="trash-alt" />
          {'  '}
          {country.delete}
        </Button>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      width: 145px;
      display: flex;
      flex-direction: column;
      padding: 4px;
      border-right: 1px solid ${theme.colors.border.weak};
    `,
    fftName: css`
      font-size: 11px;
      font-weight: 500;
      color: #299c46;
    `,
    timeText: css`
      font-size: 10px;
      color: #299c46;
    `,
    sessionText: css`
      font-size: 10px;
    `,
    removeRef: css`
      color: #727272;
      font-size: 10px;
      border: none;
      margin-top: 10px;
      justify-content: center !important;
    `,
  };
};
