import { getBins, getOrientation, getSensorType, getSpectrumType } from '../utils/helpers';
import { TranslationProps } from '../translation';

export const getCurrentHandle = (handles: any, v: any, profiles: any) => {
  return {
    settings: {
      lower_freq: profiles[v.value][0].lowerFreq,
      upper_freq: profiles[v.value][0].upperFreq,
      freqFields: [],
      compr_type: profiles[v.value][0].comprType,
      sensor: profiles[v.value][0].sensor,
      orientation: profiles[v.value][0].orientation,
      spectrum_type: profiles[v.value][0].spectrumType,
      spectrum_param: profiles[v.value][0].spectrumParam,
    },
    beaconInfos: handles[1],
  };
};

export const getDescription = (handle: any, country: TranslationProps) => {
  const sensorType = getSensorType(handle?.sensor, country);
  const sessionTime = new Date(handle?.time).toUTCString();

  const description = `Time: ${sessionTime.replace('GMT', '')} | Min: ${handle?.lowerFreq} | Max: ${
    handle?.upperFreq
  } | Sensor: ${sensorType}`;
  return description;
};

export const getProfileDescription = (profile: any, country: TranslationProps, tag?: any[]) => {
  const sensorType = getSensorType(profile?.sensor, country);
  const orientation = getOrientation(profile?.orientation);
  const comprType = getBins(profile?.comprType);
  const spectrumType = getSpectrumType(profile?.spectrumType, country);

  /** Min-max freq | bins | orientation | sensor | spectrum type | cutoff freq | tag */
  let description = `${profile?.lowerFreq}-${profile?.upperFreq}Hz | ${comprType} ${country.bins} ${
    sensorType === country.accelerometer ? `| ${orientation}` : ''
  } | ${sensorType} | Spectrum ${spectrumType} ${
    profile?.spectrumType === 5 || profile?.spectrumType === 6 ? `| Cutoff frequency ${profile.spectrumParam}Hz` : ''
  }`;

  if (tag?.length) {
    description += ` | Tag: ${tag[0].tag}`;
  }
  return description;
};
