// units
export const unit_hertz = 'FREQUENCY (Hz)';
export const unit_rpm = 'FREQUENCY (Rpm)';
export const scale_log = 'log';

// datasources
export const mysql_db_name = 'mysql_datasource';
export const influx_db_name = 'asystom_db';
export const json_datasource = 'DashGen';

// graph layout
export const middle_section_heatmap = 0.55;
export const sidebar_menu_left_width = 160;
