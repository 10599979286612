import React, { useState, useCallback, useEffect } from 'react';
import { Checkbox, useStyles2 } from '@grafana/ui';
import { useAppState } from '../AppContext/AppStateContext';
import { getSensorType } from '../utils/helpers';
import { css } from '@emotion/css';

export interface PlotsParametersProps {}

type Option = 'log' | 'gUnit' | 'rpm' | 'mms';

export const PlotsParameters: React.FunctionComponent<PlotsParametersProps> = () => {
  const { state, dispatch } = useAppState();
  const { currentHandle, isGUnit, isMms, country } = state;

  const [options, setOptions] = useState({ log: false, gUnit: false, rpm: false, mms: false });
  const styles = useStyles2(getStyles);

  const switchOption = useCallback((option: Option, isChecked: boolean) => {
    switch (option) {
      case 'log':
        setOptions((prevState) => ({ ...prevState, log: isChecked }));
        dispatch({ type: 'SET_LOG', payload: isChecked });
        break;

      case 'gUnit':
        setOptions((prevState) => ({ ...prevState, gUnit: isChecked }));
        dispatch({ type: 'SET_G_UNIT', payload: isChecked });
        break;

      case 'rpm':
        setOptions((prevState) => ({ ...prevState, rpm: isChecked }));
        dispatch({ type: 'SET_RPM', payload: isChecked });
        break;

      case 'mms':
        setOptions((prevState) => ({ ...prevState, mms: isChecked }));
        dispatch({ type: 'SET_MMS', payload: isChecked });
        break;

      default:
        break;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // temporary workaround to allow plugin build

  const onChangeOption = useCallback(
    (e: React.FormEvent<HTMLInputElement>, option: Option) => {
      const isChecked = e.currentTarget.checked;
      switchOption(option, isChecked);
    },
    [switchOption]
  );

  useEffect(() => {
    if (isGUnit) {
      setOptions((prevState) => ({ ...prevState, gUnit: true }));
    }
  }, [isGUnit]); // eslint-disable-line react-hooks/exhaustive-deps
  // temporary workaround to allow plugin build

  useEffect(() => {
    if (isMms) {
      setOptions((prevState) => ({ ...prevState, mms: true }));
    }
  }, [isMms]); // eslint-disable-line react-hooks/exhaustive-deps
  // temporary workaround to allow plugin build

  return (
    <div className={styles.checkboxContainer}>
      <div>
        <Checkbox
          value={options.log}
          onChange={(e) => onChangeOption(e, 'log')}
          label="Log"
          data-testid="checkbox-log"
          description={country.changeToLog}
          size={10}
        />
      </div>
      {getSensorType(currentHandle.settings.sensor, country) === country.accelerometer && (
        <>
          {/* Mm/s vs g */}
          {currentHandle.settings.spectrum_type !== 3 && currentHandle.settings.spectrum_type !== 4 ? (
            <div style={{ marginLeft: '15px' }}>
              <Checkbox
                value={options.gUnit}
                onChange={(e) => onChangeOption(e, 'gUnit')}
                label="G unit"
                data-testid="checkbox-gUnit"
                description={country.convertToG}
              />
            </div>
          ) : (
            <div style={{ marginLeft: '15px' }}>
              <Checkbox
                value={options.mms}
                onChange={(e) => onChangeOption(e, 'mms')}
                label="mm/s"
                data-testid="checkbox-mmsUnit"
                description={country.convertToMms}
              />
            </div>
          )}

          <div style={{ marginLeft: '15px' }}>
            <Checkbox
              value={options.rpm}
              onChange={(e) => onChangeOption(e, 'rpm')}
              label="Rpm"
              data-testid="checkbox-rpm"
              description={country.convertToRpm}
            />
          </div>
        </>
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    checkboxContainer: css`
      padding: 4px 15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    `,
  };
};
