export interface TranslationProps {
  accelerometer: string;
  activation: string;
  addTag: string;
  bins: string;
  burst: string;
  cancel: string;
  changeToLog: string;
  chooseRef: string;
  clickOnHeatmap: string;
  compression: string;
  convertToG: string;
  convertToMms: string;
  convertToRpm: string;
  dashboardButton: string;
  delete: string;
  deleteTag: string;
  downloadCSV: string;
  editSettings: string;
  enterProfileTag: string;
  errorLangSelection: string;
  fftProfiles: string;
  freqHz: string;
  freqRpm: string;
  gateway: string;
  generatingData: string;
  inactive: string;
  maxFFT: string;
  maxFreq: string;
  microphone: string;
  minFreq: string;
  noFFT: string;
  noSelectSession: string;
  orientation: string;
  periodic: string;
  plotsParameters: string;
  profile: string;
  profileInfo: string;
  querySessions: string;
  selectedFFT: string;
  selectOneSession: string;
  selectSession: string;
  selectTwoSessions: string;
  sensor: string;
  settings: string;
  spectrogram: string;
  tagProfile: string;
  time: string;
  value: string;
  version: string;
}

/**
 * Proceed all the translations
 */

export const translate = (selectCountry: string) => {
  let country = selectCountry;

  const EN: TranslationProps = {
    accelerometer: 'Accelerometer',
    activation: 'Activation',
    addTag: 'Save tag',
    bins: 'bands',
    burst: 'Burst',
    cancel: 'Cancel',
    changeToLog: 'Change X axis to log10',
    chooseRef: 'Choose as reference',
    clickOnHeatmap: 'CLICK ON HEATMAP ON THE LEFT TO DISPLAY GRAPH ON THE RIGHT',
    compression: 'Compression',
    convertToG: 'Convert dB values to g unit',
    convertToRpm: 'Convert Hz values to rpm',
    convertToMms: 'Convert dB values',
    dashboardButton: 'ZOOM FFT VISUALIZATION',
    delete: 'Delete',
    deleteTag: 'Delete tag',
    downloadCSV: 'Download CSV',
    editSettings: 'Edit settings',
    enterProfileTag: 'Enter profile tag',
    errorLangSelection:
      'lang_selection variable is missing in the dashboard settings. English will be used as default language.',
    fftProfiles: 'Profiles',
    freqHz: 'FREQUENCY (Hz)',
    freqRpm: 'FREQUENCY (Rpm)',
    gateway: 'Gateway',
    generatingData: 'Generating Zoom FFT data ...',
    inactive: 'Inactive',
    maxFFT: 'Maximum FFT selected',
    maxFreq: 'Max Frequency',
    microphone: 'Microphone',
    minFreq: 'Min Frequency',
    noFFT: 'No Zoom FFT found. Check that the Zoom FFT parameters have been sent.',
    noSelectSession: 'No other session found',
    orientation: 'Orientation',
    periodic: 'Periodic',
    plotsParameters: 'Plots parameters',
    profile: 'Profile',
    profileInfo: 'Profiles group together sessions with similar parameters',
    querySessions: 'Query sessions...',
    selectedFFT: 'Selected FFT',
    selectOneSession: 'Select at least one session to display spectrogram',
    selectSession: 'Select session',
    selectTwoSessions: 'Select two sessions to compare',
    sensor: 'Sensor',
    settings: 'Profile settings',
    spectrogram: 'Spectrogram',
    tagProfile: 'Tag profile',
    time: 'Time',
    value: 'Value',
    version: 'Version',
  };
  const FR: TranslationProps = {
    accelerometer: 'Accéléromètre',
    activation: 'Activation',
    addTag: 'Enregistrer',
    bins: 'bandes',
    burst: 'Burst',
    cancel: 'Annuler',
    changeToLog: "Changer l'axe X en log10",
    chooseRef: 'Choisir comme référence',
    clickOnHeatmap: 'CLIQUEZ SUR LA HEATMAP À GAUCHE POUR AFFICHER LES GRAPHES À DROITE',
    compression: 'Compression',
    convertToG: 'Convertir les valeurs dB en g',
    convertToRpm: 'Convertir les valeurs Hz en rpm',
    convertToMms: 'Convertir les valeurs dB en',
    dashboardButton: 'FFT ZOOM VISUALISATION',
    delete: 'Supprimer',
    deleteTag: 'Supprimer le tag',
    downloadCSV: 'Télécharger en CSV',
    editSettings: 'Modifier les paramètres',
    enterProfileTag: 'Tag du profil',
    errorLangSelection:
      "La variable lang_selection n'existe pas dans les paramètres du tableau de bord.L'anglais sera utilisé comme langue par défaut.",
    fftProfiles: 'Profils',
    freqHz: 'FREQUENCE (Hz)',
    freqRpm: 'FREQUENCE (Rpm)',
    gateway: 'Passerelle',
    generatingData: 'Génération des données FFT Zoom ...',
    inactive: 'Inactive',
    maxFFT: 'Le maximum de FFT sélectionnées est atteint',
    maxFreq: 'Fréquence max',
    microphone: 'Microphone',
    minFreq: 'Fréquence min',
    noFFT: 'Aucune FFT Zoom trouvée. Verifiez que les paramètres FFT Zoom ont été envoyés.',
    noSelectSession: 'Aucune autre session trouvée',
    orientation: 'Orientation',
    periodic: 'Périodique',
    plotsParameters: 'Paramètres des graphiques',
    profile: 'Profil',
    profileInfo: 'Les profiles regroupent les sessions aux paramètres similaires',
    querySessions: 'Récupération des sessions ... ',
    selectedFFT: 'FFT Sélectionnées',
    selectOneSession: 'Sélectionnez au moins une session pour afficher le spectrogramme',
    selectSession: 'Sélectionnez une session',
    selectTwoSessions: 'Sélectionnez deux sessions à comparer',
    sensor: 'Capteur',
    settings: 'Paramètres du profil',
    spectrogram: 'Spectrogramme',
    tagProfile: 'Ajouter un tag au profil',
    time: 'Date',
    value: 'Valeur',
    version: 'Version',
  };
  return country === 'EN' ? EN : FR;
};
