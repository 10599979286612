/**
 * Math conversions
 */

export const gConvert = (value: number) => {
  return 16 * Math.pow(10, value / 20);
};

/** db to mm/s || in/s */
export const velocityConvert = (value: number, lengthUnit: string) => {
  if (lengthUnit === 'meter' || !lengthUnit) {
    return 100 * Math.pow(10, value / 20);
  }
  return (100 * Math.pow(10, value / 20)) / 25.4;
};

export const velocityArrayConvert = (array: number[], lengthUnit: string) => {
  return array.map((value: number) => velocityConvert(value, lengthUnit));
};

export const gArrayConvert = (array: number[]) => {
  return array.map((value: number) => gConvert(value));
};

export const rpmConvert = (value: number) => {
  return value * 60;
};

export const rpmArrayConvert = (array: number[]) => {
  return array.map((value: number) => rpmConvert(value));
};

export const logConvert = (value: number) => {
  return Math.log10(value);
};

export const logArrayConvert = (array: number[]) => {
  if (!array.length) {
    return [];
  }
  return array.map((value: number) => logConvert(value));
};

const asciiToUint8 = (str: string) => {
  if (!str.length || str.length > 2) {
    return;
  }
  return parseInt(str, 16);
};

const asciiToUint32 = (str: string) => {
  // null, undefined and valid length checks
  if (!str.length || str.length > 8) {
    return;
  }

  // ! Non-null assertion operator to prevent ts error :
  // Object is possibly 'null'.ts(2531)

  return parseInt('0x' + str.match(/../g)!.reverse().join(''), 16);
};

export const convertValue = (settingsValue: string) => {
  const handle = asciiToUint8(settingsValue.substring(0, 2));
  const activation = asciiToUint8(settingsValue.substring(2, 4));
  const steps = asciiToUint8(settingsValue.substring(4, 6));
  const algorithm = asciiToUint8(settingsValue.substring(6, 8));
  const sensor = asciiToUint8(settingsValue.substring(8, 10));
  const orientation = asciiToUint8(settingsValue.substring(10, 12));

  const upper_freq = asciiToUint32(settingsValue.substring(12, 20));
  const lower_freq = asciiToUint32(settingsValue.substring(20, 28));
  const compr_type = asciiToUint32(settingsValue.substring(28, 36));
  const spectrum_type = asciiToUint32(settingsValue.substring(36, 44));
  const spectrum_param = asciiToUint32(settingsValue.substring(44, 52));

  const settings = [
    { name: 'handle', value: handle },
    { name: 'activation', value: activation },
    { name: 'steps', value: steps },
    { name: 'algorithm', value: algorithm },
    { name: 'sensor', value: sensor },
    { name: 'orientation', value: orientation },
    { name: 'upper_freq', value: upper_freq },
    { name: 'lower_freq', value: lower_freq },
    { name: 'compr_type', value: compr_type },
    { name: 'spectrum_type', value: spectrum_type },
    { name: 'spectrum_param', value: spectrum_param },
  ];
  return settings;
};
