import { useState, useLayoutEffect } from 'react';
import { useAppState } from '../AppContext/AppStateContext';
import { mysqlRequest } from 'utils/helpers';

/**
 * Get references profiles saved in db by user
 * time: string | session: string | profile: string
 */

export const useFFTProfilesRefs = () => {
  const { state, dispatch } = useAppState();
  const { datasources, currentProfile, firstSessionNb: sessionFirst, secondSessionNb, beacon } = state;
  const [profiles, setProfiles] = useState([] as any);

  const getFFTSessionsRef = async () => {
    const queryFFTRefs = `SELECT * FROM fft_reference WHERE mac_address='${beacon}';`;
    const queryResults = await mysqlRequest(datasources.mysqlFFT.uid, queryFFTRefs).catch((error: any) =>
      console.log(error)
    );

    if (!queryResults) {
      return [];
    }
    const rows = queryResults;
    const profiles = rows?.map((row: any) => {
      return {
        time: row[0],
        session: row[1],
        profile: row[2],
        id: row[3],
      };
    });
    setProfiles(profiles);
    dispatch({ type: 'SET_PROFILES', payload: profiles });
    return;
  };

  useLayoutEffect(() => {
    if (datasources?.mysqlFFT?.id) {
      getFFTSessionsRef();
    }
  }, [datasources, currentProfile, sessionFirst, secondSessionNb]); // eslint-disable-line react-hooks/exhaustive-deps
  return profiles;
};
