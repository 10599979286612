import React, { useLayoutEffect, useEffect, useRef } from 'react';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js'.
import Plotly from 'plotly.js-cartesian-dist';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js'.
import * as csDictionary from 'plotly.js/lib/locales/fr.js';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js/factory'.
import createPlotlyComponent from 'react-plotly.js/factory';
import { useAppState } from '../AppContext/AppStateContext';
import { Alert, Spinner, useStyles2 } from '@grafana/ui';
import { PanelData } from '@grafana/data';
import { css } from '@emotion/css';
import { layoutHeatMap } from './layouts';
import { formatTimeToLocale, getHeatMapPlotObject, getSensorType, getTime } from '../utils/helpers';
import { middle_section_heatmap } from '../utils/globals';
import { gConvert, logArrayConvert, rpmConvert, velocityConvert } from '../utils/conversions';
import {
  addAnnotation,
  addRefAnnotation,
  addRefShape,
  addShape,
  changeDomain,
  changeRangeSliderThickness,
  changeXaxisTitleAndUnit,
  changeYref,
  getAnnotSecondHeatmap,
  getPositionXAnnotation,
  handleFFTSelect,
  keepAnnotFirstHeatmap,
  removeUserChanges,
  removeZoom,
} from '../utils/plotsCosmetic';

Plotly.register(csDictionary);
const Plot = createPlotlyComponent(Plotly);

export interface DisplayUIPlotsProps {
  data: PanelData;
  graphDataToDisplay: any[];
  graphData: any[];
  fileTitle: string;
}

export const DisplayUIPlots: React.FunctionComponent<DisplayUIPlotsProps> = ({
  data,
  graphDataToDisplay,
  graphData,
  fileTitle,
}) => {
  const { dispatch, state } = useAppState();
  const {
    country,
    currentHandle,
    currentProfile,
    fft,
    fftSessionRef,
    firstSessionNb,
    firstSessionValues,
    highlightTime,
    isGUnit,
    isLog,
    isMms,
    isRpm,
    lang,
    listIsLoading,
    secondSessionNb,
    secondSessionValues,
    lengthUnit,
  } = state;

  const { settings } = currentHandle;
  const { sensor, freqFields, spectrum_type } = settings;
  const sensorType = getSensorType(sensor, country);
  const firstSessionTimeList = getTime(firstSessionValues) as any[]; // Get time values (first element of the array) as y  for the spectrogramm
  let secondSessionTimeList = [] as any;

  const currentFreqFields = freqFields;
  const previousSecondSession = usePrevious(secondSessionNb); // Used to avoid loading  dataset twice

  let firstSessionUnitGValues = [] as any;
  let firstSessionUnitMmsValues = [] as any; // or in/s
  let secondSessionHeatmapValues = {};
  let secondSessionUnitDbValues = [] as any;
  let secondSessionUnitGValues = [] as any;
  let secondSessionUnitMmsValues = [] as any; // or in/s

  const logFreq = logArrayConvert(currentFreqFields);
  const freqStart = logFreq.filter((log: any) => log !== -Infinity); // Filter infinity value otherwise the scale of the graph is falsed
  const xValue = getPositionXAnnotation(isLog, isRpm, currentFreqFields, freqStart[0]);
  const x1Rpm = isRpm
    ? rpmConvert(currentFreqFields[currentFreqFields.length - 1])
    : currentFreqFields[currentFreqFields.length - 1];

  // const dateScreenshot = new Date(Date.now()); // Used to build screenshot's file name
  const styles = useStyles2(getStyles);

  const setError = (name: string, value: boolean) => {
    dispatch({ type: 'SET_ERROR', payload: { errorName: name, value: value } });
  };

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const firstSessionUnitDbValues = firstSessionValues.map((session) =>
    session
      .filter((el: any, index: number) => index > 1)
      .filter((el: any) => el !== null)
      .filter((el: any) => typeof el === 'number')
  );

  // if sensor type equals to accelerometer, convert by default in g
  if (sensorType && sensorType === country.accelerometer && spectrum_type !== 3 && spectrum_type !== 4) {
    firstSessionUnitDbValues.map((data) => {
      let arrayToPush = data.map((d: any) => gConvert(d));
      firstSessionUnitGValues.push(arrayToPush);
    });
  }

  /**
   * if spectrum type is Velocity RMS (3) or Velocity Peak (4),
   * convert db values in mm/s or in/s
   */
  if (spectrum_type && isMms) {
    firstSessionUnitDbValues.map((data) => {
      let arrayToPush = data.map((d: any) => velocityConvert(d, lengthUnit));
      firstSessionUnitMmsValues.push(arrayToPush);
    });
  }

  /**
   * HANDLE CLICK ON HEATMAP CELL
   * Filter ffts by time
   * Add annotation on heatmap
   */

  const getFftByTime = (e: any) => {
    if (!e) {
      return;
    }

    if (e.points[0].data.xaxis === 'x3' && e.points[0].data.yaxis === 'y3') {
      return;
    }

    if (
      fftSessionRef.length &&
      e.points[0].data.y[e.points[0].pointIndex[0]].slice(0, 29) === fftSessionRef[0].time.slice(0, 29)
    ) {
      return;
    }
    const dateClick = e.points[0].data.y[e.points[0].pointIndex[0]];

    // Reset errors
    setError('selectedSession', false);
    setError('maxFft', false);

    // curveNumber exposes the plot (here session) that has been clicked when two plots are displayed
    let session = e.points[0].curveNumber === 0 ? firstSessionValues : secondSessionValues;

    // Filter fft by time
    const fftData = session.filter((el) => el[0] === dateClick);

    const fftFiltered = fftData[0]
      ?.filter((el: any, index: number) => index > 1) // Remove first 2 elements (time, GW)
      .filter((el: any) => el !== null)
      .filter((el: any) => typeof el === 'number');

    // If fft is not already selected, add fft values otherwise remove fft
    const indexFound = fft?.findIndex((el: any) => el.time === dateClick);

    if (indexFound === -1) {
      if (fft.length < 6) {
        // limit comparison at 6 fft maximum
        dispatch({
          type: 'ADD_FFT_VALUES',
          payload: {
            values: fftFiltered,
            time: dateClick,
            name: `${fft.length + 1}`,
            session: fftData[0][fftData[0]?.length - 1].toString(10),
            timeRef: e.points[0].data.y[e.points[0].pointIndex[0]],
          },
        });
        const selectedShape = addShape(e, isRpm, x1Rpm, currentFreqFields[1]);
        const selectAnnotation = addAnnotation(e, xValue, dateClick, fft.length);

        layoutHeatMap.annotations.push(selectAnnotation);
        layoutHeatMap.shapes.push(selectedShape);
      } else {
        setError('maxFft', true);
      }
    } else {
      dispatch({ type: 'REMOVE_FFT', payload: indexFound });
    }
  };

  /**
   * Remove fft on annotation click
   */

  const handleClickAnnotation = (e: any) => {
    if (e.annotation.text === 'REF') {
      return;
    }
    let annotationsIndex = fft.findIndex((el) => el.time === e.annotation.y);
    dispatch({ type: 'REMOVE_FFT', payload: annotationsIndex });
  };

  /**
   * Change title of heatmap on profile change
   * Reset user zoom changes
   * Remove annotations and shapes
   */

  useLayoutEffect(() => {
    layoutHeatMap.title.text = country.spectrogram.toUpperCase();
    layoutHeatMap.title.text = `${layoutHeatMap.title.text} ${sensorType.toUpperCase()}`;
    setError('maxFft', false);
    removeUserChanges(layoutHeatMap);
  }, [currentHandle]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Highlight graph.
   * Keep highlight on unit change and on refresh.
   * Listening to data.request.requestId allows to catch the refresh event, used to display highlight fft.
   */
  useLayoutEffect(() => {
    const formatHighlight = formatTimeToLocale(highlightTime, lang);
    const findIndexHighlight = graphData.findIndex((data: { text: string }) => data.text === formatHighlight);
    if (findIndexHighlight !== -1) {
      graphData[findIndexHighlight].line.width = 1;
      graphData[findIndexHighlight].line.color = 'red';
    }
  }, [highlightTime, fft, isLog, isRpm, isMms, isGUnit, data.request?.requestId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Add fft on click if it's not already selected
   * Change fft previous names
   */

  useLayoutEffect(() => {
    handleFFTSelect(fft, layoutHeatMap);
  }, [fft, fftSessionRef]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Add second heatmap on second session select
   * Show rangeslider on xaxis when one session is selected
   */

  useLayoutEffect(() => {
    let rangeSliderSize = secondSessionValues?.length ? false : true;
    let startYSection = secondSessionValues?.length ? middle_section_heatmap : 0;
    changeDomain(layoutHeatMap.yaxis, [startYSection, 1]);
    changeRangeSliderThickness(layoutHeatMap.xaxis, rangeSliderSize);
    removeZoom(layoutHeatMap);
  }, [secondSessionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Change xaxis title on unit change
   */

  useLayoutEffect(() => {
    changeXaxisTitleAndUnit(layoutHeatMap, isLog, isRpm, currentFreqFields, country);
    let unitValue = 'dB';
    if (isGUnit) {
      unitValue = 'g';
    }
    if (isMms) {
      unitValue = lengthUnit === 'meter' ? 'mm/s' : 'inch';
    }

    layoutHeatMap.yaxis3.title.text = unitValue;
    if (!fft.length) {
      removeZoom(layoutHeatMap);
    }
  }, [fft, isLog, isRpm, isGUnit, isMms]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * On unit change, remove zoom
   */

  useLayoutEffect(() => {
    removeZoom(layoutHeatMap);
  }, [isLog, isRpm, isGUnit, isMms]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Remove annotations of second heatmap if second session is removed.
   * Keep annotations of second heatmap if first session is removed
   */
  useLayoutEffect(() => {
    if (secondSessionValues?.length === 0 && secondSessionNb !== '' && previousSecondSession !== firstSessionNb) {
      layoutHeatMap.annotations = keepAnnotFirstHeatmap(layoutHeatMap.annotations);
      layoutHeatMap.shapes = keepAnnotFirstHeatmap(layoutHeatMap.shapes);
      dispatch({ type: 'REMOVE_BATCH_FFT', payload: secondSessionNb });
      dispatch({ type: 'SET_SESSION_COMPARE', payload: '' });
    }
    if (previousSecondSession === firstSessionNb && secondSessionNb !== '') {
      removeZoom(layoutHeatMap);
      const getAnnotYref = getAnnotSecondHeatmap(layoutHeatMap.annotations);
      const getShapesYref = getAnnotSecondHeatmap(layoutHeatMap.shapes);
      layoutHeatMap.annotations = changeYref(getAnnotYref);
      layoutHeatMap.shapes = changeYref(getShapesYref);
      dispatch({ type: 'SET_SESSION_COMPARE', payload: '' });
    }
  }, [secondSessionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Add annotation and shape on heatmap when a fft zoom reference is related to profile
   */
  useLayoutEffect(() => {
    if (currentProfile && fftSessionRef?.length) {
      if (!secondSessionNb && fftSessionRef[0].session !== firstSessionNb) {
        return;
      }
      if (fftSessionRef[0].session !== secondSessionNb && fftSessionRef[0].session !== firstSessionNb) {
        return;
      }

      const selectedShape = addRefShape(isRpm, currentFreqFields[1], x1Rpm, fftSessionRef[0], secondSessionNb);
      const selectAnnotation = addRefAnnotation(xValue, fftSessionRef[0], secondSessionNb);
      layoutHeatMap.annotations.push(selectAnnotation);
      layoutHeatMap.shapes.push(selectedShape);
    }
  }, [fftSessionRef, firstSessionValues, fft, secondSessionNb]); // eslint-disable-line react-hooks/exhaustive-deps

  if (secondSessionValues?.length) {
    secondSessionTimeList = getTime(secondSessionValues);
    secondSessionUnitDbValues = secondSessionValues?.map((session) =>
      session
        .filter((el: any, index: number) => index > 1)
        .filter((el: any) => el !== null)
        .filter((el: any) => typeof el === 'number')
    );

    if (sensorType && sensorType === country.accelerometer && spectrum_type !== 3 && spectrum_type !== 4) {
      secondSessionUnitDbValues?.map((data: number[]) => {
        let arrayToPush = data.map((d: number) => gConvert(d));
        secondSessionUnitGValues.push(arrayToPush);
      });
    }

    /**
     * if spectrum type is Velocity RMS (3) or Velocity Peak (4),
     * convert db values in mm/s
     */
    if (spectrum_type && isMms) {
      secondSessionUnitDbValues.map((data: number[]) => {
        let arrayToPush = data.map((d: number) => velocityConvert(d, lengthUnit));
        secondSessionUnitMmsValues.push(arrayToPush);
      });
    }

    let data = isGUnit ? secondSessionUnitGValues : isMms ? secondSessionUnitMmsValues : secondSessionUnitDbValues;
    secondSessionHeatmapValues = getHeatMapPlotObject(
      isRpm,
      currentFreqFields,
      secondSessionTimeList,
      isGUnit,
      data,
      secondSessionNb,
      'y2',
      country,
      isMms,
      lengthUnit
    );
  }

  let fftData = isGUnit ? firstSessionUnitGValues : isMms ? firstSessionUnitMmsValues : firstSessionUnitDbValues;
  let firstHeatmapValues = getHeatMapPlotObject(
    isRpm,
    currentFreqFields,
    firstSessionTimeList,
    isGUnit,
    fftData,
    firstSessionNb,
    'y',
    country,
    isMms,
    lengthUnit
  );

  const localeClient = lang === 'FR' ? 'fr' : 'en';

  return (
    <div className={styles.secondRowContainer}>
      <div className={styles.plotContainer}>
        {listIsLoading && (
          <div className={styles.loadContainer}>
            <Spinner size={20} />
            {country.generatingData}
          </div>
        )}
        {!listIsLoading && currentProfile && !firstSessionValues.length && (
          <div className={styles.alertContainer}>
            <Alert title={country.selectOneSession} severity="info" />
          </div>
        )}
        {!listIsLoading && !graphDataToDisplay.length && firstSessionValues.length !== 0 && (
          <div className={styles.clickMessage}>{country.clickOnHeatmap}</div>
        )}
        {/* Plots */}
        {firstSessionValues.length !== 0 && !listIsLoading && (
          <div>
            <Plot
              onClick={(e: any) => getFftByTime(e)}
              onClickAnnotation={(e: any) => handleClickAnnotation(e)}
              data={[firstHeatmapValues, secondSessionHeatmapValues, ...graphDataToDisplay]}
              layout={layoutHeatMap}
              config={{
                locale: localeClient,
                scrollZoom: true,
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                  format: 'png',
                  filename: `FFTZoom_${fileTitle}.png`,
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    plotContainer: css`
      width: 100%;
      top: 32px;
      text-align: center;
    `,
    secondRowContainer: css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,
    loadContainer: css`
      width: 100%;
      height: 70%;
      min-height: 540px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `,
    alertContainer: css`
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 50vh;
    `,
    exportButton: css`
      display: flex;
      justify-content: flex-end;
      padding: 6px;
      height: 36px;
    `,
    clickMessage: css`
      position: relative;
      font-style: italic;
      font-size: 12px;
      height: 36px;
      padding: 6px;
    `,
  };
};
