import { getDataSourceSrv, getBackendSrv } from '@grafana/runtime';
import { convertValue, rpmArrayConvert } from './conversions';

const ASYSTOM_VALUE_LENGTH_WITH_HANDLE = 52; //Only keep version 4.34 (with handle)
const SET_POINT_TO_MIDDLE = 2; // needed to fit heatmap xaxis

/**
 * Check if sessions have data
 * If not, exclude session
 */

export const checkDataSessions = async (handlesInfos: any, macAddress: string, ds: any) => {
  const sessionsWithData = [] as any;
  for (const info of handlesInfos) {
    // only getting settings where timestamp is greater than SettingsSignatureBackup's timestamp
    const queryValues = `SELECT GW, bin0000 FROM Signature_SpectrumZoom WHERE ("device" ='${macAddress}') AND handle='${info.handle}' AND time > '${info.time}' ORDER BY time ASC LIMIT 1`;
    await getBackendSrv()
      .get(`api/datasources/proxy/uid/${ds.uid}/query?db=${ds.name}&q=${queryValues}`)
      .then((data) => {
        if (data.results[0].series) {
          // info.time = data.results[0].series[0].values[0][0];
          sessionsWithData.push(info);
          return sessionsWithData;
        }
        return;
      })
      .catch(() => console.log('error getting sessions'));
  }
  return sessionsWithData;
};

/**
 * Retrieve all handles and their parameters
 */

export const getAllHandles = async (macAddress: string) => {
  const ds: any = await getDataSourceSrv()
    .get('asystom_db')
    .catch(() => console.log('error requesting datasource'));

  let valuesToConvert: any = [];
  let timeStamp: any = [];
  // only get settings sent by client (where version is null)
  const querySettings = `SELECT * FROM SettingsSignatureBackup WHERE ("device" ='${macAddress}') AND version='' ORDER BY time DESC`;
  const queryResult = await getBackendSrv()
    .get(`api/datasources/proxy/uid/${ds.uid}/query?db=asystom_db&q=${querySettings}`)
    .catch(() => console.log('error getting beacon settings'));

  const results = queryResult.results[0].series[0];

  // Beacon infos = time, GW, client, command, device, value, version
  const beaconInfos = results.columns
    .map((column: any, indexColumn: number) => {
      column === 'value' && valuesToConvert.push(results.values.map((value: any) => value[indexColumn]));
      column === 'time' && timeStamp.push(results.values.map((value: any) => value[indexColumn]));
      return {
        name: column,
        value: results.values[0][indexColumn],
      };
    })
    .filter((column: any) => column.name !== 'time')
    .filter((column: any) => column.name !== 'value');

  const handlesInfos = valuesToConvert[0]
    .filter((v: string, i: number) => valuesToConvert[0].indexOf(v) === i)
    .filter((value: string) => value.length === ASYSTOM_VALUE_LENGTH_WITH_HANDLE)
    .map((value: string, index: number) => {
      const convertedValue = convertValue(value) as any;
      return {
        time: timeStamp[0][index],
        handle: convertedValue[0].value,
        steps: convertedValue[2].value,
        algorithm: convertedValue[3].value,
        sensor: convertedValue[4].value,
        orientation: convertedValue[5].value,
        lower_freq: convertedValue[7].value,
        upper_freq: convertedValue[6].value,
        compr_type: convertedValue[8].value,
        spectrum_type: convertedValue[9].value,
        spectrum_param: convertedValue[10].value,
      };
    });

  // Remove sessions without data
  const sessionsWithData = await checkDataSessions(handlesInfos, macAddress, ds);

  const groupByHandle = (objectArray: any, property: string) => {
    if (!objectArray) {
      return;
    }
    return objectArray.reduce(function (acc: any, obj: any, index: number) {
      let cle = obj[property];
      if (!acc[cle] || (acc[cle] && acc[cle].time < obj.time)) {
        // if 2 handles or more exist, keep the most recent
        acc[cle] = {
          time: obj.time,
          handle: obj.handle,
          steps: obj.steps,
          algorithm: obj.algorithm,
          sensor: obj.sensor,
          orientation: obj.orientation,
          lowerFreq: obj.lower_freq,
          upperFreq: obj.upper_freq,
          comprType: obj.compr_type,
          spectrumType: obj.spectrum_type,
          spectrumParam: obj.spectrum_param,
        };
      }
      return acc;
    }, {});
  };
  const handleGrouped = groupByHandle(sessionsWithData, 'handle');
  return [handleGrouped, beaconInfos];
};

export const groupByProfile = (handles: any, country: any) => {
  const objectLength = Object.keys(handles).length;
  if (!objectLength) {
    return;
  }

  let profiles = [] as any;
  for (const property in handles) {
    profiles.push(handles[property]);
  }

  let key = 1;
  const profilesGrouped = profiles.reduce(function (acc: any, obj: any, index: number) {
    let sessionToString = obj.handle.toString();
    if (index === 0) {
      acc[key] = [
        {
          steps: obj.steps,
          algorithm: obj.algorithm,
          sensor: obj.sensor,
          orientation: obj.orientation,
          lowerFreq: obj.lowerFreq,
          upperFreq: obj.upperFreq,
          comprType: obj.comprType,
          spectrumType: obj.spectrumType,
          spectrumParam: obj.spectrumParam,
        },
        { handles: [{ session: sessionToString, time: obj.time }] },
      ];
    } else {
      for (const property in acc) {
        if (
          acc[property][0][`upperFreq`] === obj[`upperFreq`] &&
          acc[property][0]['lowerFreq'] === obj['lowerFreq'] &&
          getBins(acc[property][0]['comprType']) === getBins(obj['comprType']) &&
          getSensorType(acc[property][0]['sensor'], country) === getSensorType(obj['sensor'], country) &&
          getOrientation(acc[property][0]['orientation']) === getOrientation(obj['orientation']) &&
          acc[property][0]['spectrumType'] === obj['spectrumType'] &&
          acc[property][0]['spectrumParam'] === obj['spectrumParam']
        ) {
          acc[property][1].handles.push({ session: sessionToString, time: obj['time'] });
          return acc;
        }
      }
      key += 1;
      acc[key] = [
        {
          steps: obj.steps,
          algorithm: obj.algorithm,
          sensor: obj.sensor,
          orientation: obj.orientation,
          lowerFreq: obj.lowerFreq,
          upperFreq: obj.upperFreq,
          comprType: obj.comprType,
          spectrumType: obj.spectrumType,
          spectrumParam: obj.spectrumParam,
        },
        { handles: [{ session: sessionToString, time: obj.time }] },
      ];
    }
    return acc;
  }, {});
  return profilesGrouped;
};

export const getBins = (comprType: number) => {
  switch (true) {
    case comprType === 0 || comprType === 1: {
      return 50;
    }
    case comprType === 2 || comprType === 3: {
      return 100;
    }
    case comprType === 4: {
      return 200;
    }
    default:
      return 200;
  }
};

export const getSensorType = (sensorType: number, country: any) => {
  switch (true) {
    case sensorType === 1 || sensorType === 2 || sensorType === 3: {
      return country.accelerometer;
    }
    case sensorType === 4 || sensorType === 8 || sensorType === 12: {
      return country.microphone;
    }
    default:
      return country.sensor;
  }
};

export const getSpectrumType = (spectrumType: number, country: any) => {
  switch (spectrumType) {
    case 0: {
      return 'N/A';
    }
    case 1: {
      return 'RMS';
    }
    case 2: {
      return 'Peak';
    }
    case 3: {
      return 'Velocity RMS';
    }
    case 4: {
      return 'Velocity Peak';
    }
    case 5: {
      return 'Envelope RMS';
    }
    case 6: {
      return 'Envelope Peak';
    }
    default:
      return '';
  }
};

export const getOrientation = (orientation: number) => {
  switch (orientation) {
    case 0: {
      return 'XYZ';
    }
    case 1: {
      return 'X';
    }
    case 2: {
      return 'Y';
    }
    case 4: {
      return 'Z';
    }
    default:
      return '';
  }
};

export const getFreqFields = (upperFreq: number, lowerFreq: number, bins: number) => {
  const freqRange = (upperFreq - lowerFreq) / bins;
  let freqIncremented = lowerFreq; // start increment with lowest frequency
  const freqFields = [lowerFreq]; // fill first element of array with lower frequency

  for (let i = 1; i <= bins; i++) {
    freqIncremented += freqRange;
    freqFields.push(freqIncremented);
  }
  return freqFields;
};

export const getFreqFieldsGraph = (upperFreq: number, lowerFreq: number, bins: number) => {
  const freqRange = (upperFreq - lowerFreq) / bins;
  const startFreq = lowerFreq + freqRange / SET_POINT_TO_MIDDLE;
  let freqIncremented = startFreq; // start increment with lowest frequency
  const freqFields = [startFreq]; // fill first element of array with lowest frequency

  for (let i = 1; i <= bins; i++) {
    freqIncremented += freqRange;
    freqFields.push(freqIncremented);
  }
  return freqFields;
};

export const getFormatedInfos = (settings: any, country: any) => {
  let formatedInfos = [] as any;

  const pushToFormatedInfos = (name: string, value: any) => {
    formatedInfos.push({ name: name, value: value });
  };

  settings.map((setting: any) => {
    const valueSetting = setting.value;
    const nameSetting = setting.name;
    switch (nameSetting) {
      case 'time': {
        pushToFormatedInfos('Session start', new Date(valueSetting).toUTCString().replace('GMT', ''));
        break;
      }
      case 'lower_freq': {
        pushToFormatedInfos(country.minFreq, `${valueSetting} Hz`);
        break;
      }
      case 'upper_freq': {
        pushToFormatedInfos(country.maxFreq, `${valueSetting} Hz`);
        break;
      }
      case 'sensor': {
        pushToFormatedInfos(country.sensor, getSensorType(valueSetting, country));
        break;
      }
      case 'compr_type': {
        pushToFormatedInfos(country.compression, `${getBins(valueSetting)} ${country.bins}`);
        break;
      }
      case 'GW': {
        pushToFormatedInfos(country.gateway, `N°${valueSetting}`);
        break;
      }
      case 'orientation': {
        pushToFormatedInfos(country.orientation, getOrientation(valueSetting));
        break;
      }
      case 'spectrum_type': {
        pushToFormatedInfos('Spectrum type', getSpectrumType(valueSetting, country));
        break;
      }
      case 'spectrum_param': {
        pushToFormatedInfos('Cutoff Freq.', `${valueSetting}Hz`);
        break;
      }
      default:
        pushToFormatedInfos(nameSetting, valueSetting);
    }
  });
  return formatedInfos;
};

export const sliceDate = (date: string) => {
  if (!date) {
    return;
  }
  return date.slice(0, 19);
};

export const formatTimeToLocale = (time: string, lang: string) => {
  if (!time) {
    return;
  }
  let locale = '';
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  } as any;

  if (lang === 'EN') {
    locale = 'en-US';
  } else {
    locale = 'fr-FR';
  }
  const dateFFT = new Date(time);
  const dateLocale = dateFFT.toLocaleDateString(locale, options);
  return dateLocale.replace(',', '');
};

export const getTime = (array: any[]) => {
  if (!array) {
    return;
  }
  return array?.map((el: string) => el[0]);
};

export const getHeatMapPlotObject = (
  isRpm: boolean,
  fields: number[],
  timeList: any[],
  isGUnit: boolean,
  data: any[],
  session: string,
  yaxis: string,
  country: any,
  isMms: boolean,
  lengthUnit: string
) => {
  return {
    x: isRpm ? rpmArrayConvert(fields) : fields,
    y: timeList,
    z: data,
    type: 'heatmap',
    coloraxis: 'coloraxis',
    xaxis: 'x',
    yaxis: yaxis,
    hovertemplate: `Freq: %{x} ${isRpm ? 'Rpm' : 'Hz'}<br>${country.value}: %{z} ${
      isGUnit ? 'g' : isMms ? (lengthUnit === 'meter' ? 'mm/s' : 'in/s') : 'dB'
    }<br>${country.time}: %{y}<br><extra>Session ${session}</extra>`,
  };
};

export const computeResolution = (settings: any) => {
  let bins = getBins(settings['compr_type']);
  const resolution = (settings['upper_freq'] - settings['lower_freq']) / bins;
  return resolution.toString(10);
};

const API_MYSQL_REQUEST = 'api/ds/query';

export const mysqlRequest = async (dsUid: string, query: string) => {
  const mysqlResults = await getBackendSrv().post(API_MYSQL_REQUEST, {
    queries: [
      {
        datasource: {
          type: 'mysql',
          uid: dsUid,
        },
        format: 'table',
        rawSql: query,
        refId: 'tempvar',
      },
    ],
  });

  const results = mysqlResults.results.tempvar.frames;
  if (results.length) {
    const resultsArrays = results[0].data.values;
    const finalresult = resultsArrays[0].map(() => new Array(resultsArrays.length));
    for (const [indexArray, array] of resultsArrays.entries()) {
      for (const [indexValue, value] of array.entries()) {
        finalresult[indexValue][indexArray] = value;
      }
    }
    return finalresult;
  } else {
    return [];
  }
};
