import React, { useState, useCallback, useLayoutEffect } from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { useStyles2, IconButton, Button } from '@grafana/ui';
import { formatTimeToLocale } from '../utils/helpers';
import { useAppState } from '../AppContext/AppStateContext';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export interface FFTMenuProps {
  index: number;
  time: string;
  session: string;
  timeRef: string;
}

/**
 * List of FFT clicked by user
 * Displays FFT Name, time and session number
 * Button to highlight fft on graph
 */

export const FFTMenu: React.FunctionComponent<FFTMenuProps> = ({ index, time, session, timeRef }) => {
  const [highlightChecked, setHighlightChecked] = useState(false);
  const { dispatch, state } = useAppState();
  const { highlightTime, fft, currentProfile, datasources, lang, country, theme, user, beacon } = state;
  const styles = useStyles2(getStyles);
  const dateLocale = formatTimeToLocale(time, lang);

  const WRITE_API_URL = `api/datasources/proxy/uid/${datasources.json.uid}`;
  const FFT_REFERENCE_ENDPOINT = `${WRITE_API_URL}/fft_reference`;

  const removeFft = () => {
    dispatch({ type: 'SET_ERROR', payload: { errorName: 'maxFft', value: false } });
    dispatch({ type: 'REMOVE_FFT', payload: index });
  };

  const onChange = useCallback(() => {
    let check = highlightChecked ? false : true;
    let payloadHighlight = highlightChecked ? '' : time;
    setHighlightChecked(check);
    dispatch({ type: 'SET_HIGHLIGHT_TIME', payload: payloadHighlight });
  }, [highlightChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChooseRef = async () => {
    const refTimeSplit = timeRef.split('+');
    const refTime = `${refTimeSplit[0]}Z`;

    const fftProfilePayload = {
      mac_address: beacon,
      fft_time: refTime,
      fft_session: session,
      fft_profile: currentProfile,
    };

    // Save reference in db
    await getBackendSrv()
      .put(FFT_REFERENCE_ENDPOINT, fftProfilePayload, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(() => {
        console.log('error requestiong db');
      });

    // Update ref ui side
    const refValues = fft.filter((fft, index) => {
      if (fft.time === time) {
        dispatch({ type: 'REMOVE_FFT', payload: index });
      }
      return fft.time === time;
    });
    dispatch({
      type: 'SET_REF_PROFILE',
      payload: [{ time: timeRef, session: session, profile: currentProfile, values: refValues[0]?.values }],
    });
  };

  useLayoutEffect(() => {
    if (highlightTime !== time && highlightTime !== '' && highlightChecked) {
      setHighlightChecked(false);
    }
    if (highlightTime === time && highlightTime !== '' && !highlightChecked) {
      setHighlightChecked(true);
    }
  }, [highlightTime, fft]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`${styles.mainContainer} ${highlightChecked && styles.backgroundHighlight}`}>
      <div>
        <div className={styles.sessionText}>
          <div onClick={onChange} data-testid={`$close-fft-${index}`}>
            <span className={styles.fftName} onClick={onChange}>
              FFT{index + 1}{' '}
            </span>
            <span style={{ color: theme.colors.text.secondary }}>session {session}</span>
          </div>
          <IconButton onClick={() => removeFft()} name="times" size="sm" />
        </div>
        <span className={styles.timeText} onClick={onChange}>
          {dateLocale}
        </span>
      </div>
      {user.orgRole !== 'Viewer' && (
        <div className={styles.buttonContainer}>
          <div className={styles.switchContainer}>
            <Button onClick={onChooseRef} className={styles.ref} size="sm" variant="secondary">
              {country.chooseRef}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      width: 142px;
      display: flex;
      flex-direction: column;
      padding: 7px;
      margin: 2px;
    `,
    backgroundHighlight: css`
      background-color: #ffffaf;
    `,
    fftName: css`
      font-size: 10px;
      font-weight: 500;
      color: ${theme.colors.text.primary};
      cursor: pointer;
    `,
    timeText: css`
      font-size: 10px;
      cursor: pointer;
      color: ${theme.colors.text.secondary};
    `,
    sessionText: css`
      font-size: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    `,
    switchContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    buttonContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    `,
    ref: css`
      font-size: 10px;
      color: #727272;
      border: none;
      margin-top: 4px;
      text-align: center;
      justify-content: center !important;
    `,
  };
};
