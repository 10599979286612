import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useStyles2, Icon, Tooltip } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';
import { useAppState } from 'AppContext/AppStateContext';
import { Tag } from 'AppContext/types';

import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export interface TagProfileProps {}

export const TagProfile: React.FunctionComponent<TagProfileProps> = () => {
  const { state, dispatch } = useAppState();
  const { datasources, currentProfile, allTags, country, user, beacon } = state;
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [tag, setTag] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const styles = useStyles2(getStyles);

  const WRITE_API_URL = `api/datasources/proxy/uid/${datasources.json.uid}`;
  const FFT_PROFILE_ENDPOINT = `${WRITE_API_URL}/fft_profile`;

  const resetState = () => {
    setIsInputVisible(false);
    setInputValue('');
    setIsEditing(false);
  };

  const showInput = () => {
    setIsInputVisible(true);
  };

  const onInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const addTag = async () => {
    if (!inputValue) {
      setIsInputVisible(false);
      return;
    }
    const currentProfileTag = allTags.tags.filter((t) => t.profile === currentProfile);

    let indexTag = 0;
    let newTags = [] as Tag[];
    if (currentProfileTag.length) {
      indexTag = currentProfileTag[0].id;
      newTags = [...allTags.tags, ...currentProfileTag];
    } else {
      newTags = [...allTags.tags, { tag: inputValue, profile: currentProfile, id: indexTag }];
    }

    const fftProfilePayload = {
      mac_address: beacon,
      profile_value: currentProfile,
      tag_value: inputValue,
    };

    await getBackendSrv()
      .put(FFT_PROFILE_ENDPOINT, fftProfilePayload, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch((err) => console.log(err));

    dispatch({ type: 'SET_PROFILES_TAGS', payload: { tags: newTags, isTagsLoaded: true } });
    setTag(inputValue);
    resetState();
  };

  const getAllTag = async () => {
    const getProfileTag = allTags?.tags?.filter((profile: { profile: string }) => profile.profile === currentProfile);
    setTag(getProfileTag[0]?.tag);
    return;
  };

  const renameTag = () => {
    if (user.orgRole === 'Viewer') {
      return;
    }
    setIsEditing(true);
    setIsInputVisible(true);
    setInputValue(tag);
  };

  const cancel = () => {
    resetState();
  };

  const removeTag = async () => {
    const payloadReference = {
      mac_address: beacon,
      profile_value: currentProfile,
    };

    await getBackendSrv()
      .delete(FFT_PROFILE_ENDPOINT, payloadReference, {
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .catch(() => {
        console.log('error while deleting tag');
      });

    const removeTags = allTags.tags.filter((t) => t.profile !== currentProfile);
    dispatch({ type: 'SET_PROFILES_TAGS', payload: { tags: removeTags, isTagsLoaded: true } });
    resetState();
    setTag('');
  };

  useEffect(() => {
    getAllTag();
  }, [currentProfile, allTags.tags]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    resetState();
    setTag('');
  }, [currentProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {tag && !isEditing && (
        <div className={styles.tag} onClick={() => renameTag()}>
          <Icon name="tag-alt" style={{ marginRight: '3px' }} />
          {tag}
        </div>
      )}
      {!isInputVisible && !tag && user.orgRole !== 'Viewer' && (
        <div className={styles.ref} onClick={showInput}>
          <Icon name="plus" style={{ marginRight: '3px' }} />
          {country.tagProfile}
        </div>
      )}
      {isInputVisible && (
        <input
          type="text"
          placeholder={country.enterProfileTag}
          value={inputValue}
          onChange={onInputChange}
          maxLength={50}
          size={15}
          width={16}
          className={styles.inputStyle}
        />
      )}
      {isInputVisible && (
        <Tooltip content={country.addTag} placement="top">
          <div className={styles.save} onClick={addTag}>
            <Icon name="save" />
          </div>
        </Tooltip>
      )}
      {isInputVisible && (
        <Tooltip content={country.cancel} placement="top">
          <div className={styles.save} onClick={cancel}>
            <Icon name="times" />
          </div>
        </Tooltip>
      )}
      {isInputVisible && tag && (
        <Tooltip content={country.deleteTag} placement="top">
          <div className={`${styles.ref} ${styles.buttonTrash}`} onClick={removeTag}>
            <Icon name="trash-alt" />
          </div>
        </Tooltip>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    inputStyle: css`
      border: 1px solid ${theme.colors.border.medium};
      padding: 3px 5px;
      border-radius: 4px;
      font-size: 12px;
      height: 22px;
      margin-left: 5px;
    `,
    ref: css`
      font-size: 11px;
      font-weight: 500;
      background-color: #969696;
      color: #ffffff;
      margin-bottom: 5px;
      cursor: pointer;
      border: none;
      padding: 0px 5px;
      border-radius: 4px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      &:hover {
        background-color: #838383;
      }
    `,
    save: css`
      font-size: 11px;
      background-color: #269c5f;
      color: #ffffff;
      margin-bottom: 5px;
      cursor: pointer;
      border: none;
      padding: 0px 5px;
      border-radius: 4px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 5px;
      &:hover {
        background-color: #1a7f4b;
      }
    `,
    tag: css`
      padding: 1px 4px;
      border-radius: 3px;
      background-color: #e5eefd;
      border: 1px solid #9ec1f7;
      color: #093374;
      cursor: pointer;
      font-size: 12px;
      height: 22px;
      margin-left: 5px;
    `,
    buttonTrash: css`
      background-color: #c70606;
    `,
  };
};
