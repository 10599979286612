import * as React from 'react';
import { getDataSourceSrv } from '@grafana/runtime';
import { useAppState } from './AppContext/AppStateContext';
import { mysql_db_name, influx_db_name, json_datasource } from './utils/globals';

/**
 * Query datasources data
 */

export const useDatasources = () => {
  const [influx, setInflux] = React.useState({} as any);
  const [mysqlFFT, setMysqlFFT] = React.useState({} as any);
  const [json, setJson] = React.useState({} as any);

  const { dispatch } = useAppState();

  const getDs = React.useCallback(async () => {
    const dsMysql = await getDataSourceSrv()
      .get(mysql_db_name)
      .catch((e) => {
        console.log('error datasource request');
      });

    const dsInflux = await getDataSourceSrv()
      .get(influx_db_name)
      .catch((e) => {
        console.log('error datasource request');
      });

    const dsJson = await getDataSourceSrv()
      .get(json_datasource)
      .catch((e) => {
        console.log('error datasource request');
      });

    setInflux(dsInflux);
    setMysqlFFT(dsMysql);
    setJson(dsJson);

    dispatch({ type: 'SET_DATASOURCES', payload: { mysqlFFT: dsMysql, influx: dsInflux, json: dsJson } });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // temporary workaround to allow plugin build

  React.useEffect(() => {
    getDs();
  }, [getDs]);

  return { mysqlFFT, influx, json };
};
