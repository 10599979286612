import { colorwayFft } from '../utils/plotsCosmetic';
import { sidebar_menu_left_width } from '../utils/globals';

const plotSize = window.innerWidth - sidebar_menu_left_width;

export let layoutHeatMap = {
  title: {
    text: 'SPECTROGRAM',
    font: {
      size: 14,
      color: '',
    },
  },
  margin: {
    t: 80,
    r: 80,
    b: 40,
    l: 100,
  },
  width: plotSize,
  height: 540,
  paper_bgcolor: '',
  plot_bgcolor: 'blue',
  autosize: false,
  colorway: colorwayFft,
  coloraxis: {
    colorscale: 'Portland',
    colorbar: {
      thickness: 15,
      x: 0.37,
      xanchor: 'right',
      tickfont: {
        color: '',
      },
    },
  },
  // first heatmap xaxis (frequency)
  xaxis: {
    anchor: 'y1',
    color: '',
    title: {
      text: 'FREQUENCY (Hz)',
      font: {
        color: '',
        size: 11,
      },
    },
    rangeslider: {
      thickness: 0.1,
      autorange: true,
      visible: true,
    },
    autorange: true,
    type: '',
    domain: [0, 0.3],
    fixedrange: true,
    side: 'bottom',
    zeroline: false,
  },
  // second heatmap xaxis (frequency)
  xaxis2: {
    anchor: 'y2',
    color: '',
    title: {
      text: 'FREQUENCY (Hz)',
      font: {
        color: '',
        size: 11,
      },
    },
    autorange: true,
    type: '',
    domain: [0, 0.3],
    fixedrange: true,
    side: 'bottom',
    automargin: true,
    zeroline: false,
  },
  // graph xaxis (frequency)
  xaxis3: {
    anchor: 'y3',
    color: '',
    title: {
      text: 'FREQUENCY (Hz)',
      font: {
        color: '',
        size: 11,
      },
    },
    autorange: true,
    type: '',
    domain: [0.43, 1],
    zeroline: false,
  },
  // first heatmap yaxis (time)
  yaxis: {
    anchor: 'x1',
    color: '',
    title: {
      font: {
        color: '',
        size: 11,
      },
    },
    type: 'date',
    fixedrange: false,
    domain: [0, 1],
  },
  // second heatmap yaxis (time)
  yaxis2: {
    anchor: 'x2',
    color: '',
    title: {
      font: {
        color: '',
        size: 11,
      },
    },
    type: 'date',
    fixedrange: false,
    domain: [0, 0.45],
  },
  // graph yaxis (fft value)
  yaxis3: {
    anchor: 'x3',
    color: '',
    title: {
      text: 'dB',
      font: {
        color: '',
        size: 16,
      },
    },
    autorange: true,
  },
  annotations: [] as any,
  shapes: [] as any,
  uirevision: 'true' as any,
  showlegend: true,
};
