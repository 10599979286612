import React, { useLayoutEffect } from 'react';
import { Select, useStyles2, Tooltip, Icon } from '@grafana/ui';
import { css } from '@emotion/css';
import { useAppState } from '../AppContext/AppStateContext';
import { TagProfile } from './TagProfile';
import { getBins, getSensorType, getFreqFields } from '../utils/helpers';
import { getCurrentHandle, getProfileDescription } from './sessionsHelpers';

export interface FFTProfilesProps {
  handles: any;
  profiles: any;
  loadProfiles: any;
  onSessionChange: any;
  setProfileValue: any;
  profileValue: any;
}

export const FFTProfiles: React.FunctionComponent<FFTProfilesProps> = ({
  handles,
  profiles,
  loadProfiles,
  onSessionChange,
  setProfileValue,
  profileValue,
}) => {
  const { state, dispatch } = useAppState();
  const { country } = state;
  const styles = useStyles2(getStyles);

  const resetState = () => {
    dispatch({ type: 'RESET_FFT' });
    dispatch({ type: 'SET_G_UNIT', payload: false });
    dispatch({ type: 'SET_MMS', payload: false });
    dispatch({ type: 'ADD_SESSION_LIST', payload: [] });
    dispatch({ type: 'ADD_SESSION_LIST_COMPARE', payload: [] });
  };

  /**
   * Handle profiles change
   */

  const onProfileChange = (v: any) => {
    resetState();
    dispatch({ type: 'SET_CURRENT_PROFILE', payload: v.value });
    dispatch({ type: 'SET_REF_PROFILE', payload: [] });
    const currentHandle = getCurrentHandle(handles, v, profiles);
    const currentSettings = currentHandle.settings;
    let bins = getBins(currentSettings.compr_type);
    let freqFields = getFreqFields(currentSettings.upper_freq, currentSettings.lower_freq, bins);
    currentSettings.freqFields = freqFields as any;
    const isMmsUnit = currentSettings.spectrum_type === 3 || currentSettings.spectrum_type === 4;

    dispatch({ type: 'ADD_CURRENT_HANDLE', payload: currentHandle });
    setProfileValue(v);

    if (getSensorType(profiles[v.value][0].sensor, country) === country.accelerometer && !isMmsUnit) {
      dispatch({ type: 'SET_G_UNIT', payload: true });
    }

    if (isMmsUnit) {
      dispatch({ type: 'SET_MMS', payload: true });
    }

    // Display by default the last session of the profile
    const lastHandleIndex = profiles[v.value][1].handles.length - 1;
    onSessionChange([
      {
        label: `Session ${profiles[v.value][1].handles[lastHandleIndex].session}`,
        value: profiles[v.value][1].handles[lastHandleIndex].session,
        description: `${country.time} : ${profiles[v.value][1].handles[lastHandleIndex].time}`,
      },
    ]);
  };

  useLayoutEffect(() => {
    if (!profiles) {
      dispatch({ type: 'SET_ERROR', payload: { errorName: 'loadSessions', value: true } });
      return;
    }
    if (Object.keys(profiles).length) {
      dispatch({ type: 'SET_ERROR', payload: { errorName: 'loadSessions', value: false } });

      // Display by default the profile of the last session
      let lastSession = 0;
      let lastProfile = 0;
      for (const [key, value] of Object.entries(profiles as any) as any) {
        value[1] &&
          value[1].handles.forEach((handle: any) => {
            if (parseInt(handle.session, 10) > lastSession) {
              lastSession = parseInt(handle.session, 10);
              lastProfile = key;
            }
          });
      }
      dispatch({ type: 'SET_CURRENT_PROFILE', payload: lastProfile.toString(10) });
      if (profiles[lastProfile][0].spectrum_type === 3 || profiles[lastProfile][0].spectrum_type === 4) {
        dispatch({ type: 'SET_MMS', payload: true });
      }
      onProfileChange({
        label: `${country.profile} ${lastProfile}`,
        value: lastProfile,
        description: getProfileDescription(profiles[lastProfile][0], country),
      });
    }
  }, [profiles]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.selectWrapper}>
      <div className={styles.titleSession}>
        <div>
          {country.fftProfiles.toUpperCase()}{' '}
          <Tooltip content={country.profileInfo} placement="right">
            <Icon name="info-circle"></Icon>
          </Tooltip>
        </div>
      </div>
      <div className={styles.containerDateSelect}>
        <Select
          options={loadProfiles}
          value={profileValue}
          width={53}
          onChange={onProfileChange}
          data-testid="select-profile"
          prefix={<Icon name="check" />}
        />
        <TagProfile />
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    selectWrapper: css`
      width: 50%;
      display: flex;
      flex-direction: column;
    `,
    containerDateSelect: css`
      display: flex;
      flex-direction: row;
    `,
    titleSession: css`
      font-size: 13px;
      margin-bottom: 5px;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    `,
  };
};
