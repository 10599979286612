import React from 'react';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { getFormatedInfos, computeResolution } from '../utils/helpers';
import { useAppState } from '../AppContext/AppStateContext';
import { GrafanaTheme2 } from '@grafana/data';

export interface SettingsProps {
  settings: any;
  beaconInfos: any;
}

export const DisplayInfos: React.FunctionComponent<SettingsProps> = ({ settings, beaconInfos }) => {
  const { state } = useAppState();
  const { country } = state;
  const styles = useStyles2(getStyles);

  const beaconInfosDisplayed = beaconInfos
    .filter((info: { name: string }) => info.name !== 'value')
    .filter((info: { name: string }) => info.name !== 'version')
    .filter((info: { name: string }) => info.name !== 'command')
    .filter((info: { name: string }) => info.name !== 'device')
    .filter((info: { name: string }) => info.name !== 'client')
    .filter((info: { name: string }) => info.name !== 'GW');

  let settingsFiltered = [];
  // Sensor / Orientation / MinMax Freq / Compression / Resolution / Spectrum Type / Cut off
  const paramsOrder = [
    'sensor',
    'orientation',
    'upper_freq',
    'lower_freq',
    'compr_type',
    'resolution',
    'spectrum_type',
    'spectrum_param',
  ];

  for (const setting of paramsOrder) {
    if (setting === 'lower_freq') {
      settingsFiltered.push({
        name: 'MIN / MAX FREQ.',
        value: `${settings['lower_freq']} - ${settings['upper_freq']}Hz`,
      });
      continue;
    }
    if (setting === 'upper_freq') {
      continue;
    }
    // Remove orientation if microphone is selected
    if (setting === 'orientation' && settings['sensor'] === 12) {
      continue;
    }

    if (setting === 'spectrum_param') {
      if (settings['spectrum_type'] === 5 || settings['spectrum_type'] === 6) {
        settingsFiltered.push({ name: setting, value: settings[setting] });
        continue;
      }
      continue;
    }

    if (setting === 'resolution') {
      const resolution = computeResolution(settings);
      settingsFiltered.push({ name: setting, value: `${resolution}Hz` });
      continue;
    }

    if (setting !== 'freqFields' && setting !== 'handle' && setting !== 'time') {
      settingsFiltered.push({ name: setting, value: settings[setting] });
    }
  }

  const settingsDisplayed = [...settingsFiltered, ...beaconInfosDisplayed];
  const formatedInfos = getFormatedInfos(settingsDisplayed, country);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.container} data-testid="settings">
        {formatedInfos.map((setting: { name: string; value: any }) => {
          return (
            <div key={setting.name} className={styles.cell}>
              <div className={styles.title}>{setting.name.toUpperCase()}</div>
              <div className={styles.text}>{setting.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      display: flex;
      flex-direction: column;
      background: ${theme.isDark ? theme.colors.background.secondary : '#e9edf2'};
      padding: 8px 22px;
      width: 100%;
      margin-bottom: 5px;
    `,
    container: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      width: 100%;
    `,
    cell: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      margin: 5px;
      width: 100%;
      color: ${theme.colors.text.primary};
    `,
    mainTitle: css`
      font-size: 13px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    title: css`
      font-size: 11px;
    `,
    text: css`
      font-size: 16px;
    `,
  };
};
