import * as React from 'react';
import { useAppState } from 'AppContext/AppStateContext';
import { Tag } from 'AppContext/types';
import { mysqlRequest } from 'utils/helpers';

/**
 * Get tags profiles saved in db by user
 * tag: string | profile: string
 */

export const useProfilesTags = () => {
  const { state, dispatch } = useAppState();
  const { datasources, currentProfile, beacon } = state;
  const [allTags, setAllTags] = React.useState([] as Tag[]);

  const getTagsProfiles = React.useCallback(async () => {
    if (datasources?.mysqlFFT?.id) {
      const queryFFTRefs = `SELECT * FROM fft_profile_tag WHERE mac_address='${beacon}';`;
      const queryResults = await mysqlRequest(datasources.mysqlFFT.uid, queryFFTRefs).catch((error: any) =>
        console.log(error)
      );

      if (!queryResults) {
        return [];
      }

      const profilesTags = queryResults.map((row: any) => {
        return {
          tag: row[0],
          profile: row[1],
          id: row[2],
        };
      });

      dispatch({ type: 'SET_PROFILES_TAGS', payload: { tags: profilesTags, isTagsLoaded: true } });
      setAllTags(profilesTags);
      return profilesTags;
    }
  }, [datasources]); // eslint-disable-line react-hooks/exhaustive-deps
  // temporary workaround to allow plugin build

  React.useEffect(() => {
    getTagsProfiles();
  }, [currentProfile, getTagsProfiles]);

  return allTags;
};
