import * as React from 'react';
import { PanelProps } from '@grafana/data';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { AppStateProvider } from './AppContext/AppStateContext';
import { FFTPage } from './FFTPage';

export interface Props extends PanelProps {}
/**
 * Entry of plugin, provides application state
 */
export const SimplePanel: React.FunctionComponent<Props> = ({ data }) => {
  const styles = useStyles2(getStyles);
  return (
    <AppStateProvider>
      <div className={styles.wrapper}>
        <FFTPage data={data} />
      </div>
    </AppStateProvider>
  );
};

const getStyles = () => {
  return {
    wrapper: css`
      width: 100%;
      height: 100%;
      margin-top: 8px;
    `,
  };
};
