import * as React from 'react';
import { useAppState } from './AppContext/AppStateContext';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js'.
import Plot from 'react-plotly.js';
import { useStyles2, Modal } from '@grafana/ui';
import { css } from '@emotion/css';
import { SelectSession } from './SelectSession/SelectSession';
import { PlotsSection } from './Plots/PlotsSection';
import { useDatasources } from './useDatasources';
import { useProfilesTags } from './SelectSession/useProfilesTags';
import { layoutHeatMap } from './Plots/layouts';
import { ModalButton } from './ModalButton';
import { PanelData } from '@grafana/data';

interface Props {
  data: PanelData;
}

/**
 * Query datasources and profile tags.
 * Contains modal to be displayed on button click
 */

export const FFTPage: React.FunctionComponent<Props> = ({ data }) => {
  const { state, dispatch } = useAppState();
  const { theme, isModalOpen, country } = state;
  const { allTags, errors } = state;
  const styles = useStyles2(getStyles);
  const widthModal = document.getElementById('modalWrapper')?.offsetWidth;

  // Adapt plots theme to Grafana theme
  layoutHeatMap.width = widthModal as any;
  layoutHeatMap.paper_bgcolor = theme.colors.background.primary;
  layoutHeatMap.plot_bgcolor = theme.colors.background.primary;
  layoutHeatMap.title.font.color = theme.colors.text.primary;
  layoutHeatMap.coloraxis.colorbar.tickfont.color = theme.colors.text.secondary;
  layoutHeatMap.xaxis.color = theme.colors.text.secondary;
  layoutHeatMap.xaxis2.color = theme.colors.text.secondary;
  layoutHeatMap.xaxis3.color = theme.colors.text.secondary;
  layoutHeatMap.yaxis.color = theme.colors.text.secondary;
  layoutHeatMap.yaxis2.color = theme.colors.text.secondary;
  layoutHeatMap.yaxis3.color = theme.colors.text.secondary;

  useDatasources();
  useProfilesTags();

  const resetState = () => {
    dispatch({ type: 'ADD_SESSION_LIST', payload: [] });
    dispatch({ type: 'ADD_SESSION_LIST_COMPARE', payload: [] });
    dispatch({ type: 'SET_SESSION_REFERENCE', payload: '' });
    dispatch({ type: 'RESET_FFT' });
    dispatch({ type: 'TOGGLE_MODAL', payload: false });
    dispatch({ type: 'SET_SESSION_COMPARE', payload: '' });
    dispatch({ type: 'SET_CURRENT_PROFILE', payload: '' });
    dispatch({ type: 'SET_REF_PROFILE', payload: [] });
    dispatch({ type: 'SET_HIGHLIGHT_TIME', payload: '' });
    dispatch({ type: 'SET_LOG', payload: false });
    dispatch({ type: 'SET_G_UNIT', payload: false });
    dispatch({ type: 'SET_RPM', payload: false });
    dispatch({ type: 'SET_MMS', payload: false });
  };

  return (
    <div style={{ height: '100%' }}>
      <div style={{ margin: 'auto' }}>
        <ModalButton />
      </div>
      <Modal
        isOpen={isModalOpen}
        title={<div className={styles.modalTitle}>{country.dashboardButton}</div>}
        className={styles.modalContainer}
        contentClassName={styles.overrideModalStyle}
        onDismiss={resetState}
      >
        <div className={styles.mainContainer} data-id="wrapper" id="modalWrapper">
          <div className={styles.firstRowContainer}>{isModalOpen && allTags.isTagsLoaded && <SelectSession />}</div>
          <div className={styles.secondRowContainer}>
            {isModalOpen && !errors.loadSessions && <PlotsSection data={data} />}
          </div>
        </div>
      </Modal>
    </div>
  );
};

const getStyles = () => {
  return {
    mainContainer: css`
      width: 100%;
      display: flex;
      flex-direction: column;
      min-width: 1300px;
      height: 100%;
    `,
    modalContainer: css`
      width: 100%;
      max-width: 95vw;
      margin: auto;
      height: 95vh !important;
      padding: 0px;
      max-height: 95%;
      top: 5px;
    `,
    overrideModalStyle: css`
      padding: 20px !important;
    `,
    modalTitle: css`
      padding: 10px 10px 0 10px;
    `,
    firstRowContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    secondRowContainer: css`
      display: flex;
      flex-direction: row;
    `,
  };
};
