import React, { useState, useLayoutEffect } from 'react';
import { Alert, useStyles2, MultiSelect, Spinner } from '@grafana/ui';
import { getBackendSrv } from '@grafana/runtime';
import { useAppState } from '../AppContext/AppStateContext';
import { DisplayInfos } from './DisplayInfos';
import { FFTProfiles } from './FFTProfiles';
import { getAllHandles, groupByProfile, formatTimeToLocale } from '../utils/helpers';
import { getProfileDescription } from './sessionsHelpers';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import backgroundError from '../img/screen-shot-fft.png';

export interface SelectSessionProps {}

export const SelectSession: React.FunctionComponent<SelectSessionProps> = () => {
  const { state, dispatch } = useAppState();
  const {
    beacon,
    errors,
    currentHandle,
    secondSessionValues,
    datasources,
    allTags,
    currentProfile,
    country,
    timeZone,
    lang,
    sessionsIsLoading,
  } = state;
  const [profileValue, setProfileValue] = useState({} as any);
  const [profileSession, setProfileSession] = useState([] as any);
  const [profiles, setProfiles] = useState([] as any);
  const [handles, setHandles] = useState([] as any);
  const [profilesOptions, setProfilsOptions] = useState([] as any);
  const styles = useStyles2(getStyles);

  const MAX_POINTS = 10000;

  const setError = () => {
    dispatch({ type: 'SET_ERROR', payload: { errorName: 'loadSessions', value: true } });
    dispatch({ type: 'LIST_IS_LOADING', payload: false });
    dispatch({ type: 'SESSIONS_IS_LOADING', payload: false });
  };

  const removeSessionCompare = () => {
    dispatch({ type: 'ADD_SESSION_LIST_COMPARE', payload: [] });
    dispatch({ type: 'LIST_IS_LOADING', payload: false });
  };

  /**
   * Get profiles grouped by similar parameters (select options)
   */

  const loadProfiles = async () => {
    dispatch({ type: 'SESSIONS_IS_LOADING', payload: true });
    try {
      const handleObject = await getAllHandles(beacon);
      setHandles(handleObject);
      const profiles = groupByProfile(handleObject[0], country);
      setProfiles(profiles);
      let optionsProfiles = [] as any;
      for (const property in profiles) {
        const tagProfile = allTags.tags.filter((tag) => tag.profile === property);
        const profileDesc = getProfileDescription(profiles[property][0], country, tagProfile);
        optionsProfiles.push({
          label: `${country.profile} ${property}`,
          value: property,
          description: profileDesc,
        });
      }
      setProfilsOptions(optionsProfiles);
      dispatch({ type: 'SESSIONS_IS_LOADING', payload: false });
      return optionsProfiles;
    } catch {
      setError();
      return [];
    }
  };

  /**
   * Get profile sessions (select options)
   */

  const loadProfilesSessions = () => {
    let sessionsOptions = [] as any;
    if (profileValue?.value) {
      profiles[profileValue.value][1].handles.map((handle: any) => {
        const localeDate = formatTimeToLocale(handle.time, lang);
        sessionsOptions.push({
          label: `Session ${handle.session}`,
          value: handle.session,
          description: ` ${country.time} : ${localeDate}`,
        });
      });
      return sessionsOptions;
    }
    return [];
  };

  /**
   * Get fft session values
   */

  const getSpectrumZoom = async (v: any, isCompared: boolean) => {
    // Reset loadings, session list and errors
    dispatch({ type: 'LIST_IS_LOADING', payload: true });
    dispatch({ type: 'SET_ERROR', payload: { errorName: 'loadSessions', value: false } });

    if (!v.length) {
      dispatch({ type: 'ADD_SESSION_LIST', payload: [] });
      dispatch({ type: 'SET_SESSION_REFERENCE', payload: '' });
      dispatch({ type: 'RESET_FFT' });
      removeSessionCompare();
      return;
    }

    //prevent to reload values of first choice
    if (profileSession.length === 2 && v.length === 1 && v[0].value === profileSession[0].value) {
      removeSessionCompare();
      return;
    }

    if (profileSession.length === 2 && v.length === 1 && v[0].value === profileSession[1].value) {
      dispatch({ type: 'ADD_SESSION_LIST', payload: secondSessionValues });
      dispatch({ type: 'SET_SESSION_REFERENCE', payload: v[0].value });
      dispatch({ type: 'REMOVE_BATCH_FFT', payload: profileSession[0].value });
      removeSessionCompare();
      return;
    }
    let handle = '';
    isCompared ? (handle = v[1].value) : (handle = v[0].value);
    const timeSession = handles[0][handle].time;

    /**
     * Count total of fft in the current session,
     * used to figure out if a grouping is needed or not
     */

    const queryCountValues = `SELECT COUNT(bin0000) FROM Signature_SpectrumZoom WHERE ("device" ='${beacon}') AND handle='${handle}' AND time > '${timeSession}' tz('${timeZone}')`;

    const queryCountValuesResult = await getBackendSrv()
      .get(
        `api/datasources/proxy/uid/${datasources?.influx?.uid}/query?db=${datasources?.influx?.name}&q=${queryCountValues}`
      )
      .catch(() => {
        dispatch({ type: 'LIST_IS_LOADING', payload: false });
        console.log('error while loading list');
      });

    const fftLength = queryCountValuesResult?.results[0]?.series[0].values[0][1];

    let queryFFTValues = `SELECT * FROM Signature_SpectrumZoom WHERE ("device" ='${beacon}') AND handle='${handle}' AND time > '${timeSession}' ORDER BY time DESC tz('${timeZone}')`;

    /**
     * If total of fft is > MAX_POINTS,
     * need to make a grouping to query fft
     */

    if (fftLength > MAX_POINTS) {
      // Query of the first bin timestamp

      const queryFirstBin = `SELECT FIRST(bin0000) FROM Signature_SpectrumZoom WHERE ("device" ='${beacon}') AND handle='${handle}' AND time > '${timeSession}' tz('${timeZone}')`;

      const queryFirstBinResult = await getBackendSrv()
        .get(
          `api/datasources/proxy/uid/${datasources?.influx?.uid}/query?db=${datasources?.influx?.name}&q=${queryFirstBin}`
        )
        .catch(() => {
          dispatch({ type: 'LIST_IS_LOADING', payload: false });
          console.log('error while loading list');
        });

      // Query of the last bin timestamp

      const queryLastBin = `SELECT LAST(bin0000) FROM Signature_SpectrumZoom WHERE ("device" ='${beacon}') AND handle='${handle}' AND time > '${timeSession}' tz('${timeZone}')`;

      const queryLastBinResult = await getBackendSrv()
        .get(
          `api/datasources/proxy/uid/${datasources?.influx?.uid}/query?db=${datasources?.influx?.name}&q=${queryLastBin}`
        )
        .catch(() => {
          dispatch({ type: 'LIST_IS_LOADING', payload: false });
          console.log('error while loading list');
        });

      const startTimeSession = queryFirstBinResult?.results[0]?.series[0].values[0][0];
      const endTimeSession = queryLastBinResult?.results[0]?.series[0].values[0][0];

      const formatEndTimeSession = new Date(endTimeSession) as any;
      const formatStartTimeSession = new Date(startTimeSession) as any;

      const deltaTime = Math.abs(formatEndTimeSession - formatStartTimeSession);
      const timeIntervalGroup = Math.round(deltaTime / MAX_POINTS);

      queryFFTValues = `SELECT last(*) FROM Signature_SpectrumZoom WHERE ("device" ='${beacon}') AND handle='${handle}' AND time > '${timeSession}' GROUP BY time(${timeIntervalGroup}ms) fill(none) ORDER BY time DESC tz('${timeZone}')`;
    }

    /**
     * Final request (all fft values)
     */

    const queryResult = await getBackendSrv()
      .get(
        `api/datasources/proxy/uid/${datasources?.influx?.uid}/query?db=${datasources?.influx?.name}&q=${queryFFTValues}`
      )
      .catch(() => {
        dispatch({ type: 'LIST_IS_LOADING', payload: false });
        console.log('error while loading list');
      });

    if (queryResult.results[0].series) {
      const fftValuesResult = queryResult?.results[0]?.series[0]?.values;
      if (fftLength > MAX_POINTS) {
        for (const arrayOfValues of fftValuesResult) {
          /**
           * Structure expected for the fft values array : [timestamp, GW, bin0000, bin0001, ..., client, beacon, handle]
           * As last(*) query doesn't return any tags and as the process on values has to be the same, fftLength > MAX_POINTS or not,
           * we have to push the tag values to the expected index.
           */
          arrayOfValues.push('client', beacon, handle);
          arrayOfValues.splice(1, 0, 'GW');
        }
      }

      if (isCompared) {
        dispatch({ type: 'ADD_SESSION_LIST_COMPARE', payload: fftValuesResult });
        dispatch({ type: 'SET_SESSION_COMPARE', payload: v[1].value });
      } else {
        dispatch({ type: 'ADD_SESSION_LIST_COMPARE', payload: [] });
        dispatch({ type: 'SET_SESSION_COMPARE', payload: '' });
        dispatch({ type: 'ADD_SESSION_LIST', payload: fftValuesResult });
        dispatch({ type: 'SET_SESSION_REFERENCE', payload: v[0].value });
      }
      dispatch({ type: 'LIST_IS_LOADING', payload: false });
    } else {
      setError();
    }
  };

  /**
   * Handle session profile change
   */

  const onSessionChange = async (v: any) => {
    let isCompared = false;
    if (v.length === 2) {
      isCompared = true;
    }
    if (v.length > 2) {
      return;
    }
    setProfileSession(v);
    getSpectrumZoom(v, isCompared);
  };

  useLayoutEffect(() => {
    loadProfiles();
    const timeZoneBrowser = Intl.DateTimeFormat().resolvedOptions().timeZone;
    dispatch({ type: 'SET_TIMEZONE', payload: timeZoneBrowser });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (Object.keys(profiles).length) {
      let optionsProfiles = [] as any;
      if (allTags.tags.length) {
        const tagProfile = allTags.tags.filter((tag) => tag.profile === currentProfile);
        const profileDesc = getProfileDescription(profiles[currentProfile][0], country, tagProfile);
        optionsProfiles.push({
          label: `${country.profile} ${currentProfile}`,
          value: currentProfile,
          description: profileDesc,
        });
        const newOptions = profilesOptions.map((option: { value: string }) => {
          if (option.value === currentProfile) {
            option = optionsProfiles[0];
          }
          return option;
        });
        setProfilsOptions(newOptions);
      } else {
        const profileDesc = getProfileDescription(profiles[currentProfile][0], country);
        optionsProfiles.push({
          label: `${country.profile} ${currentProfile}`,
          value: currentProfile,
          description: profileDesc,
        });
        const newOptions = profilesOptions.map((option: { value: string }) => {
          if (option.value === currentProfile) {
            option = optionsProfiles[0];
          }
          return option;
        });
        setProfilsOptions(newOptions);
      }
    }
  }, [allTags.tags]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {errors.loadSessions && (
        <div className={styles.errorBackground}>
          <div>
            <Alert title={country.noFFT} severity="warning" />
          </div>
        </div>
      )}
      {sessionsIsLoading && (
        <div className={styles.loadContainer}>
          <Spinner size={20} />
          {country.querySessions}
        </div>
      )}
      {!sessionsIsLoading && !errors.loadSessions && (
        <div>
          <div className={styles.selectContainer}>
            {/* Select FFT profiles */}
            <FFTProfiles
              handles={handles}
              profiles={profiles}
              loadProfiles={profilesOptions}
              onSessionChange={onSessionChange}
              setProfileValue={setProfileValue}
              profileValue={profileValue}
            />
            {/* Select FFT session from selected profile  */}
            <FFTSessions
              onSessionChange={onSessionChange}
              profileSession={profileSession}
              profileValue={profileValue}
              loadProfilesSessions={loadProfilesSessions}
            />
          </div>
          {/* Settings display */}
          <div className={styles.containerInfos}>
            {currentHandle?.beaconInfos?.length !== 0 && (
              <DisplayInfos settings={currentHandle.settings} beaconInfos={currentHandle.beaconInfos} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

interface FFTSessionsProps {
  loadProfilesSessions: () => any;
  onSessionChange: (v: any) => Promise<void>;
  profileSession: any;
  profileValue: any;
}

const FFTSessions: React.FunctionComponent<FFTSessionsProps> = ({
  loadProfilesSessions,
  onSessionChange,
  profileSession,
  profileValue,
}) => {
  const { state } = useAppState();
  const { country } = state;
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.selectWrapper}>
      {profileValue?.value && (
        <span>
          <span className={styles.titleSession}>SESSIONS</span>{' '}
          <span className={styles.infoSelectSession}> {country.selectTwoSessions}</span>
        </span>
      )}
      <div className={styles.containerSessionSelect}>
        {profileValue?.value && (
          <MultiSelect
            options={loadProfilesSessions()}
            placeholder={country.selectSession}
            value={profileSession}
            width={50}
            onChange={onSessionChange}
            data-testid="select-session"
            noOptionsMessage={country.noSelectSession}
          />
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    containerInfos: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    selectContainer: css`
      background-color: ${theme.isDark ? theme.colors.background.secondary : '#e9edf2'};
      display: flex;
      flex-direction: row;
      padding: 8px;
      padding: 8px 20px;
      margin-bottom: 1px;
      align-items: flex-end;
    `,
    selectWrapper: css`
      width: 50%;
      display: flex;
      flex-direction: column;
    `,
    containerSessionSelect: css`
      display: flex;
      flex-direction: row;
    `,
    titleSession: css`
      font-size: 13px;
      font-weight: 500;
    `,
    infoSelectSession: css`
      font-size: 11px;
      color: ${theme.colors.text.secondary};
    `,
    alert: css`
      width: 500px;
    `,
    loadContainer: css`
      width: 100%;
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `,
    errorBackground: css`
      background: url(${backgroundError});
      width: 100%;
      height: 650px;
      display: flex;
      align-items: center;
      justify-content: center;
    `,
  };
};
