import { AppState, Action } from './types';

/**
 * Contains all the state actions
 */

export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'ADD_LANG': {
      return {
        ...state,
        lang: action.payload,
      };
    }

    case 'ADD_FFT_VALUES': {
      let fftStateCopy = [...state.fft];
      fftStateCopy.push({
        values: action.payload.values,
        time: action.payload.time,
        name: action.payload.name,
        session: action.payload.session,
        timeRef: action.payload.timeRef,
      });
      return {
        ...state,
        fft: fftStateCopy,
      };
    }

    case 'RESET_FFT': {
      return {
        ...state,
        fft: [],
      };
    }

    case 'SET_LOG': {
      return {
        ...state,
        isLog: action.payload,
      };
    }

    case 'SET_G_UNIT': {
      return {
        ...state,
        isGUnit: action.payload,
      };
    }

    case 'SET_RPM': {
      return {
        ...state,
        isRpm: action.payload,
      };
    }

    case 'SET_MMS': {
      return {
        ...state,
        isMms: action.payload,
      };
    }

    case 'REMOVE_FFT': {
      const copyFFT = [...state.fft];
      const fftRemoved = copyFFT.filter((el, index) => (index !== action.payload ? el : false));
      return {
        ...state,
        fft: fftRemoved,
      };
    }

    case 'REMOVE_BATCH_FFT': {
      const copyFFT = [...state.fft];
      const fftRemoved = copyFFT.filter((fft) => fft.session !== action.payload);
      return {
        ...state,
        fft: fftRemoved,
      };
    }

    case 'ADD_SESSION_LIST': {
      return {
        ...state,
        firstSessionValues: action.payload,
      };
    }

    case 'ADD_SESSION_LIST_COMPARE': {
      return {
        ...state,
        secondSessionValues: action.payload,
      };
    }

    case 'SET_SESSION_COMPARE': {
      return {
        ...state,
        secondSessionNb: action.payload,
      };
    }

    case 'SET_SESSION_REFERENCE': {
      return {
        ...state,
        firstSessionNb: action.payload,
      };
    }

    case 'ADD_CURRENT_HANDLE': {
      return {
        ...state,
        currentHandle: action.payload,
      };
    }

    case 'LIST_IS_LOADING': {
      return {
        ...state,
        listIsLoading: action.payload,
      };
    }

    case 'SESSIONS_IS_LOADING': {
      return {
        ...state,
        sessionsIsLoading: action.payload,
      };
    }

    case 'SET_HIGHLIGHT_TIME': {
      return {
        ...state,
        highlightTime: action.payload,
      };
    }

    case 'SET_CURRENT_PROFILE': {
      return {
        ...state,
        currentProfile: action.payload,
      };
    }

    case 'SET_REF_PROFILE': {
      return {
        ...state,
        fftSessionRef: action.payload,
      };
    }

    case 'SET_PROFILES': {
      return {
        ...state,
        allProfiles: action.payload,
      };
    }

    case 'SET_ERROR': {
      const copyErrors: any = { ...state.errors };
      delete copyErrors[action.payload.errorName];
      return {
        ...state,
        errors: { ...copyErrors, [action.payload.errorName]: action.payload.value },
      };
    }

    case 'SET_DATASOURCES': {
      return {
        ...state,
        datasources: action.payload,
      };
    }

    case 'SET_PROFILES_TAGS': {
      return {
        ...state,
        allTags: action.payload,
      };
    }

    case 'TOGGLE_MODAL': {
      return {
        ...state,
        isModalOpen: action.payload,
      };
    }

    case 'SET_TIMEZONE': {
      return {
        ...state,
        timeZone: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
