import React from 'react';
import { useStyles2, Icon, Button } from '@grafana/ui';
import { css } from '@emotion/css';
import { useAppState } from '../AppContext/AppStateContext';
import { DisplayUIPlots } from '../Plots/DisplayUIPlots';
import { PlotsParameters } from '../Plots/PlotsParameters';
import { FFTMenu } from './FFTMenu';
import { FFTReference } from '../SelectSession/FFTReference';
import { GrafanaTheme2, PanelData, dateTimeFormat } from '@grafana/data';
import { getGraphDataObject } from '../utils/plotsCosmetic';
import { getBins, getFreqFieldsGraph, getOrientation, getSensorType } from '../utils/helpers';
import { rpmArrayConvert } from '../utils/conversions';
import { saveAs } from 'file-saver';

export interface PlotsSectionProps {
  data: PanelData;
}

export const PlotsSection: React.FunctionComponent<PlotsSectionProps> = ({ data }) => {
  const { state } = useAppState();
  const { fft, fftSessionRef, country, isRpm, isGUnit, isMms, lang, currentHandle, listIsLoading, beacon, lengthUnit } =
    state;
  const { settings } = currentHandle;
  const { upper_freq, lower_freq, compr_type } = settings;
  const styles = useStyles2(getStyles);
  let bins = getBins(compr_type);

  let frequencyGraphValues = getFreqFieldsGraph(upper_freq, lower_freq, bins);
  let freqConverted = isRpm ? rpmArrayConvert(frequencyGraphValues) : frequencyGraphValues;

  let graphData = getGraphDataObject(fft, isGUnit, freqConverted, false, lang, isMms, lengthUnit);
  const graphDataRef = getGraphDataObject(fftSessionRef, isGUnit, freqConverted, true, lang, isMms, lengthUnit);
  const graphDataToDisplay = [...graphData, ...graphDataRef];

  const orientation = getOrientation(currentHandle.settings.orientation);
  const sensor = getSensorType(settings.sensor, country);
  const fileTitle = `${beacon}_${sensor}_${orientation}_${dateTimeFormat(new Date()).replace(/ /g, '-')}`;

  const exportData = () => {
    let dataBuild: any[] = [];
    graphDataToDisplay.forEach((data, index) => {
      if (index === 0) {
        dataBuild.push(['X axis', `Y${index + 1} (${data.name.replace(/ /g, '')}-${data.text})`]);
        data.x.forEach((xAxis: number, index: number) => {
          dataBuild.push([xAxis, data.y[index]]);
        });
      } else {
        dataBuild[0].push(`Y${index + 1} (${data.name.replace(/ /g, '')}-${data.text})`);
        data.x.forEach((xAxis: number, index: number) => {
          dataBuild[index + 1].push(data.y[index]);
        });
      }
    });

    let csvContent = '';
    dataBuild.forEach((infoArray, index) => {
      let dataString = infoArray.join(',');
      csvContent += index < dataBuild.length ? dataString + '\n' : dataString;
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${fileTitle}.csv`);
  };

  return (
    <div className={styles.plotsContainer}>
      <div className={styles.paramContainer}>
        <div style={{ width: '35%' }} className={styles.titleRowContainer}>
          {/* Plots parameters */}
          <div className={styles.titleRow}>
            <span className={styles.title}>{country.plotsParameters.toUpperCase()}</span>
          </div>
          <PlotsParameters />
        </div>
        <div style={{ width: '65%' }} className={styles.titleRowContainer}>
          {/* Selected FFT */}
          <div className={styles.titleRow}>
            <div>
              <span className={styles.title}>{country.selectedFFT.toUpperCase()}</span>
              {state.errors.maxFft ? (
                <span className={styles.error}>
                  <Icon name="exclamation-triangle" size="sm" className={styles.icon} />
                  {country.maxFFT}
                </span>
              ) : (
                ''
              )}
            </div>
            {!listIsLoading && graphDataToDisplay.length !== 0 && (
              <div className={styles.exportButton}>
                <Button onClick={() => exportData()} icon={'save'} variant={'secondary'} size={'sm'}>
                  {country.downloadCSV}
                </Button>
              </div>
            )}
          </div>
          <div className={styles.menuFFTContainer}>
            {fftSessionRef && <FFTReference />}
            {fft.map((el, index) => (
              <FFTMenu index={index} time={el.time} session={el.session} key={el.time} timeRef={el.timeRef} />
            ))}
          </div>
        </div>
      </div>
      <DisplayUIPlots data={data} graphDataToDisplay={graphDataToDisplay} graphData={graphData} fileTitle={fileTitle} />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    titleRowContainer: css`
      display: flex;
      flex-direction: column;
      margin: 3px;
      background-color: ${theme.colors.background.secondary};
      padding: 8px;
      height: 110px;
    `,
    titleRow: css`
      width: 100%;
      display: flex;
      justify-content: space-between;
    `,
    title: css`
      color: ${theme.colors.text.maxContrast};
      font-size: 12px;
      font-weight: 500;
    `,
    menuFFTContainer: css`
      display: flex;
      flex-direction: row;
      height: 100%;
      width: 100%;
      justify-content: left;
    `,
    error: css`
      color: white;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 4px;
      margin-left: 7px;
      height: 20px;
      background-color: red;
      padding: 0 4px;
    `,
    plotsContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    `,
    icon: css`
      margin-right: 4px;
    `,
    paramContainer: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      border: 1px solid ${theme.colors.border.weak};
    `,
    exportButton: css`
      display: flex;
      justify-content: flex-end;
    `,
  };
};
