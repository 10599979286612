import { rpmConvert, logConvert, logArrayConvert, gArrayConvert, velocityArrayConvert } from './conversions';
import { formatTimeToLocale, sliceDate } from '../utils/helpers';
import { scale_log } from './globals';

export const getAnnotSecondHeatmap = (array: any[]) => {
  return array.filter((element: { yref: string }) => element.yref === 'y2');
};

export const keepAnnotFirstHeatmap = (array: any[]) => {
  return array.filter((element: { yref: string }) => element.yref !== 'y2');
};

/**
 * Modify yaxis to 'y' from 'y2' when first session is removed
 * (retrieve annotations from second heatmap)
 */

export const changeYref = (array: any[]) => {
  array.map((annot: { yref: string }) => {
    annot.yref = 'y';
    return annot;
  });
  return array;
};

export const changeAxisType = (axis: any, valueType: string) => {
  axis.type = valueType;
};

export const changePlotElementTitle = (axis: any, valueTitle: string) => {
  axis.title.text = valueTitle;
};

/**
 * Build graph data to be sent to Plotly
 */

export const getGraphData = (el: any, index: number, frequencyFields: any, isRef: boolean, lang: string) => {
  const formatTime = formatTimeToLocale(el.time, lang);
  return {
    x: frequencyFields,
    y: el.values,
    type: 'scatter',
    mode: 'lines',
    name: isRef ? 'REFERENCE' : `FFT ${index + 1}`,
    text: `${isRef ? sliceDate(el.time)?.replace('T', ' ') : formatTime}`,
    line: {
      width: 1,
      color: isRef ? '#05ac45' : '',
      dash: isRef ? 'dot' : '',
    },
    yaxis: 'y3',
    xaxis: 'x3',
  };
};

export const getGraphDataObject = (
  array: any,
  isGUnit: boolean,
  freqConverted: any,
  isRef: boolean,
  lang: string,
  isMms: boolean,
  lengthUnit: string
) => {
  return array?.map((el: any, index: number) => {
    if (isGUnit) {
      const objectG = { time: el.time, session: el.session, values: gArrayConvert(el.values) };
      return getGraphData(objectG, index, freqConverted, isRef, lang);
    }
    if (isMms) {
      const objectG = { time: el.time, session: el.session, values: velocityArrayConvert(el.values, lengthUnit) };
      return getGraphData(objectG, index, freqConverted, isRef, lang);
    }
    return getGraphData(el, index, freqConverted, isRef, lang);
  });
};

/**
 * On unit change, change values and xaxis title
 */

export const changeXaxisTitleAndUnit = (
  layoutHeatMap: any,
  isLog: boolean,
  isRpm: boolean,
  currentFreqFields: any,
  country: any
) => {
  const { xaxis, xaxis3, annotations, shapes } = layoutHeatMap;
  const lastFreqLength = currentFreqFields.length - 1;
  const lastFreq = currentFreqFields[lastFreqLength];
  const firstFreq = currentFreqFields[1];
  const xAxis = [xaxis, xaxis3];
  const logFreq = logArrayConvert(currentFreqFields);
  const freqStart = logFreq?.filter((log: any) => log !== -Infinity);

  if (isLog && !isRpm) {
    xAxis.map((axis) => changePlotElementTitle(axis, country.freqHz));
    xAxis.map((axis) => changeAxisType(axis, scale_log));
    annotations.map((annot: any) => (annot.x = freqStart[0]));
    shapes.map((shape: any) => {
      shape.x0 = firstFreq;
      shape.x1 = lastFreq;
      return shape;
    });
    return;
  }

  if (!isLog && isRpm) {
    xAxis.map((axis) => changePlotElementTitle(axis, country.freqRpm));
    annotations.map((annot: { x: number }) => (annot.x = rpmConvert(firstFreq)));
    xAxis.map((axis) => changeAxisType(axis, ''));
    shapes.map((shape: any) => {
      shape.x0 = rpmConvert(firstFreq);
      shape.x1 = rpmConvert(lastFreq);
      return shape;
    });
    return;
  }

  if (isLog && isRpm) {
    xAxis.map((axis) => changeAxisType(axis, scale_log));
    xAxis.map((axis) => changePlotElementTitle(axis, country.freqRpm));
    shapes.map((shape: any) => {
      shape.x0 = rpmConvert(firstFreq);
      shape.x1 = rpmConvert(lastFreq);
      return shape;
    });
    annotations.map((annot: { x: number }) => (annot.x = logConvert(rpmConvert(firstFreq))));
    return;
  } else {
    xAxis.map((axis) => changeAxisType(axis, ''));
    xAxis.map((axis) => changePlotElementTitle(axis, country.freqHz));

    annotations.map((annot: any) => (annot.x = firstFreq));
    shapes.map((shape: any) => {
      shape.x0 = firstFreq;
      shape.x1 = lastFreq;
      return shape;
    });
  }
};

/**
 * Add spectrogramm (heatmap) shape (line)
 */

export const addShape = (e: any, isRpm: boolean, x1Rpm: number, firstFreq: number) => {
  const firstPoint = e.points[0].data.y[e.points[0].pointIndex[0]];
  return {
    line: {
      width: 2,
      color: '#fcfcfc',
      dash: 'dot',
    },
    visible: true,
    type: 'line',
    layer: 'above',
    xref: 'x',
    x0: isRpm ? firstFreq * 60 : firstFreq,
    x1: x1Rpm,
    yref: e.points[0].curveNumber === 0 ? 'y' : 'y2',
    y0: firstPoint,
    y1: firstPoint,
  };
};

/**
 * Add spectrogramm (heatmap) annotation
 */

export const addAnnotation = (e: any, xValue: number, dateClick: string, fftLength: number) => {
  return {
    xref: 'x',
    yref: e.points[0].curveNumber === 0 ? 'y' : 'y2',
    x: xValue,
    y: dateClick,
    text: `FFT${fftLength + 1}`,
    bgcolor: '#f0f0f0',
    width: 35,
    showarrow: false,
    font: {
      family: 'Arial',
      size: 9,
      color: '#505050',
      fontWeight: 600,
    },
    captureevents: true,
  };
};

export const addRefShape = (isRpm: boolean, freq: number, x1Rpm: number, sessionRef: any, secondSessionNb: string) => {
  return {
    line: {
      width: 2,
      color: '#05ac45',
    },
    visible: true,
    type: 'line',
    layer: 'above',
    xref: 'x',
    x0: isRpm ? freq * 60 : freq,
    x1: x1Rpm,
    yref: sessionRef.session === secondSessionNb ? 'y2' : 'y',
    y0: sessionRef?.time,
    y1: sessionRef?.time,
  };
};

export const addRefAnnotation = (xValue: number, sessionRef: any, secondSessionNb: string) => {
  return {
    xref: 'x',
    yref: sessionRef.session === secondSessionNb ? 'y2' : 'y',
    x: xValue,
    y: sessionRef?.time,
    text: `REF`,
    bgcolor: '#05ac45',
    width: 35,
    showarrow: false,
    font: {
      family: 'Arial',
      size: 9,
      color: '#ffffff',
      fontWeight: 600,
    },
    captureevents: true,
  };
};

/**
 * Reset user zoom
 */

export const removeZoom = (layoutHeatMap: any) => {
  const rand = () => Math.random();
  const { xaxis, xaxis2, xaxis3, yaxis, yaxis2, yaxis3 } = layoutHeatMap;
  const allAxis = [xaxis, xaxis2, xaxis3, yaxis, yaxis2, yaxis3];

  const changeAutorange = (axis: any, value: boolean) => {
    axis.autorange = true;
  };

  allAxis.map((axis) => changeAutorange(axis, true));
  layoutHeatMap.uirevision = rand();
};

/**
 * Remove annotations and shapes
 */

export const removeUserChanges = (layoutHeatMap: any) => {
  layoutHeatMap.annotations = [];
  layoutHeatMap.shapes = [];
  removeZoom(layoutHeatMap);
};

/**
 * Add fft if it is not already selected
 * Change previous fft names
 */

export const handleFFTSelect = (fft: any, layoutHeatMap: any) => {
  if (fft.length === 0) {
    layoutHeatMap.annotations = [];
    layoutHeatMap.shapes = [];
    return;
  }
  let annotFiltered = [] as any;
  let shapesFiltered = [] as any;
  fft.forEach((el: any, index: number) => {
    layoutHeatMap.annotations.forEach((annot: { y: string; text: string }) => {
      const dateClick = annot.y;
      if (dateClick === el.time) {
        const copyAnnotation = { ...annot };
        copyAnnotation.text = `FFT${index + 1}`;
        annotFiltered.push(copyAnnotation);
      }
    });

    layoutHeatMap.shapes.forEach((annot: { y0: string }) => {
      if (typeof annot.y0 !== 'string') {
        return;
      }
      const dateClick = annot.y0;
      if (dateClick === el.time) {
        shapesFiltered.push(annot);
      }
    });
  });
  layoutHeatMap.annotations = annotFiltered;
  layoutHeatMap.shapes = shapesFiltered;
  return layoutHeatMap;
};

/**
 * Get x annotation and shape position when unit is changed
 */

export const getPositionXAnnotation = (isLog: boolean, isRpm: boolean, currentFreqFields: any[], freqStart: any) => {
  if (isLog && !isRpm) {
    return freqStart;
  }
  if (isLog && isRpm) {
    return logConvert(rpmConvert(currentFreqFields[1]));
  }
  if (!isLog && isRpm) {
    return rpmConvert(currentFreqFields[1]);
  }
  return currentFreqFields[0];
};

/**
 * Divide heatmap section in two when two sessions are selected
 */

export const changeDomain = (axis: any, value: number[]) => {
  axis.domain = value;
};

/**
 * Display rangeslider when one session is selected
 */

export const changeRangeSliderThickness = (axis: any, value: boolean) => {
  axis.rangeslider.visible = value;
};

/**
 * Heatmap colors
 */

export const colorwayHeatmap = [
  ['0.0', 'rgb(49,54,149)'],
  ['0.111111111111', 'rgb(69,117,180)'],
  ['0.222222222222', 'rgb(116,173,209)'],
  ['0.333333333333', 'rgb(171,217,233)'],
  ['0.444444444444', 'rgb(224,243,248)'],
  ['0.555555555556', 'rgb(254,224,144)'],
  ['0.666666666667', 'rgb(253,174,97)'],
  ['0.777777777778', 'rgb(244,109,67)'],
  ['0.888888888889', 'rgb(215,48,39)'],
  ['1.0', 'rgb(165,0,38)'],
];

/**
 * Graph colors
 */

export const colorwayFft = ['#0039A9', '#027DFF', '#3399FF', '#88D1F1', '#41AADE', '#1392D3'];
