import React, { useEffect, useState } from 'react';
import { useAppState } from './AppContext/AppStateContext';
import { getBackendSrv } from '@grafana/runtime';

import { Button, Icon } from '@grafana/ui';

export interface ModalButtonProps {}

const AVAILABLE_FFT_BEACON_VERSION = 4.34; // version from which the FFT Zoom feature is available

export const ModalButton: React.FunctionComponent<ModalButtonProps> = () => {
  const { state, dispatch } = useAppState();
  const { beacon, datasources, country } = state;
  const [isModalButtonVisible, setModalButtonVisible] = useState(false);

  const getVersion = async () => {
    const querySettings = `SELECT version, last(value) FROM SettingsSignatureBackup WHERE device = '${beacon}' AND version != ''  ORDER BY time DESC LIMIT 1`;
    const queryResult = await getBackendSrv()
      .get(
        `api/datasources/proxy/uid/${datasources?.influx?.uid}/query?db=${datasources?.influx?.name}&q=${querySettings}`
      )
      .catch(() => {
        console.log('error while querying version');
      });
    if (!queryResult.results[0].series) {
      setModalButtonVisible(false);
      return;
    }
    const currentlyVersion = queryResult.results[0].series[0].values[0][1];
    const version = currentlyVersion.replace(/[^\d.]/g, '');
    if (version < AVAILABLE_FFT_BEACON_VERSION) {
      console.log('version not ok');
      setModalButtonVisible(false);
    } else {
      setModalButtonVisible(true);
    }
  };

  useEffect(() => {
    if (Object.keys(datasources?.influx).length) {
      getVersion();
    }
  }, [datasources]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isModalButtonVisible && (
        <Button
          onClick={() => dispatch({ type: 'TOGGLE_MODAL', payload: true })}
          variant="secondary"
          style={{ margin: 'auto' }}
        >
          <Icon name="chart-line" /> {country.dashboardButton}
        </Button>
      )}
    </>
  );
};
